import React, { useState, useEffect } from 'react'
import { Row, Col, Card, Button, Form, Breadcrumb } from 'react-bootstrap';
import { API } from '../../App'
import Cookies from 'js-cookie';
import Layout from '../../layout/Layout';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const TrafficRuleLanguage = () => {
    const token = Cookies.get("fmljwt");
    const [Data, setData] = useState([])
    const [changes, setChanges] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate()

    const Get_data = async () => {
        setIsLoading(true)
        try {
            const Result = await API.post("/get_traffic_language", {}, { headers: { authorization: `${token}` } })
            setData(Result.data.data)
        } catch (error) {
            toast(error.message)
        } finally {
            setIsLoading(false)
        }
    }
    const handleOnsubmit = async () => {
        setIsLoading(true)
        try {
            const Result = await API.post("/update-traffic-rules-language", { languageArray: JSON.stringify(changes) }, { headers: { authorization: `${token}` } })
            if (Result.data.status) {
                toast.success(Result.data.response_message)
                navigate('/traffic-rule')
            }
        } catch (error) {
            toast(error.message)
            setIsLoading(false)
        } finally {
            setIsLoading(false)
        }
    }
    useEffect(() => {
        Get_data()
    }, [])

    const handleInputChange = (index, language, value, id) => {
        setData((prevData) => {
            const newData = [...prevData];
            newData[index][language] = value;
            return newData;
        });
        setChanges(prevChanges => {
            const existingChange = prevChanges.find(change => change.index === index);
            if (existingChange) {
                existingChange[language] = value;
                existingChange['id'] = id;
            } else {
                prevChanges.push({ index, [language]: value });
            }
            return [...prevChanges];
        });
        // console.log('index, language, value', index, language, value)
    };



    return (
        <div>
            <Layout sidebar={true}>
                {isLoading && <div className="loader" ></div>}
                <div className="page-heading">
                    <h3>Traffic Rules Language Add</h3>
                    <Breadcrumb className="d-none d-sm-none d-md-none d-lg-block">
                        <Breadcrumb.Item >
                            <Link to="/"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item >
                            <Link to="/traffic-rule">Traffic Rule </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Traffic Rules Language Add</Breadcrumb.Item>
                    </Breadcrumb>
                </div>

                <div className="page-content">
                    <Form >
                        {
                            Data.map((val, index) => {
                                return (
                                    <Card className="mb-4">
                                        <Card.Header className='border-bottom'>
                                            <h3 className='mb-0 fw-600'>{val.lable}</h3>
                                        </Card.Header>
                                        <Card.Body>
                                            <Row>
                                                <Col md={4}>
                                                    <Form.Label htmlFor="bengali">Bengali</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="disclaimer_bn"
                                                        className="my-2"
                                                        value={val.bn}
                                                        onChange={(e) => handleInputChange(index, 'bn', e.target.value, val.id)}
                                                        required
                                                    />
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Label htmlFor="gujarati">Gujarati</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="disclaimer_gu"
                                                        className="my-2"
                                                        value={val.gu}
                                                        onChange={(e) => handleInputChange(index, 'gu', e.target.value, val.id)}
                                                        required
                                                    />
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Label htmlFor="hindi">Hindi</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="disclaimer_hi"
                                                        className="my-2"
                                                        value={val.hi}
                                                        onChange={(e) => handleInputChange(index, 'hi', e.target.value, val.id)}
                                                        required
                                                    />
                                                </Col>

                                                <Col md={4}>
                                                    <Form.Label htmlFor="kannad">Kannad</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="disclaimer_kn"
                                                        className="my-2"
                                                        value={val.kn}
                                                        onChange={(e) => handleInputChange(index, 'kn', e.target.value, val.id)}
                                                        required
                                                    />
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Label htmlFor="malyalam">Malyalam</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="disclaimer_ml"
                                                        className="my-2"
                                                        value={val.ml}
                                                        onChange={(e) => handleInputChange(index, 'ml', e.target.value, val.id)}
                                                        required
                                                    />
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Label htmlFor="marathi">Marathi</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="disclaimer_mr"
                                                        className="my-2"
                                                        value={val.mr}
                                                        onChange={(e) => handleInputChange(index, 'mr', e.target.value, val.id)}
                                                        required
                                                    />
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Label htmlFor="odia">Odia</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="disclaimer_or"
                                                        className="my-2"
                                                        value={val.or}
                                                        onChange={(e) => handleInputChange(index, 'or', e.target.value, val.id)}
                                                        required
                                                    />
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Label htmlFor="punjabi">Punjabi</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="disclaimer_pa"
                                                        className="my-2"
                                                        value={val.pa}
                                                        onChange={(e) => handleInputChange(index, 'pa', e.target.value, val.id)}
                                                        required
                                                    />
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Label htmlFor="tamil">Tamil</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="disclaimer_ta"
                                                        className="my-2"
                                                        value={val.ta}
                                                        onChange={(e) => handleInputChange(index, 'ta', e.target.value, val.id)}
                                                        required
                                                    />
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Label htmlFor="telugu">Telugu</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="disclaimer_te"
                                                        className="my-2"
                                                        value={val.te}
                                                        onChange={(e) => handleInputChange(index, 'te', e.target.value, val.id)}
                                                        required
                                                    />
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                )
                            })
                        }
                        <Card>
                            <Card.Body className="text-end">
                                <Button onClick={handleOnsubmit} variant="primary" className="me-3">Save</Button>
                                <Link to="/traffic-rule">
                                    <Button variant="secondary">Cancel</Button>
                                </Link>
                            </Card.Body>
                        </Card>
                    </Form>
                </div>
            </Layout>
        </div>
    )
}

export default TrafficRuleLanguage