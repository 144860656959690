import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { Button, Card, Col, Row } from "react-bootstrap";
import Layout from '../../layout/Layout';
import { SelectPicker } from 'rsuite';
import { API } from '../../App';
import Cookies from "js-cookie";
import Select from 'react-select';
import { FixedSizeList } from 'react-window';

var DropDownArr = []
var reloadId = [];
const FuelPriceView = () => {
  const token = Cookies.get("fmljwt");
  const params = useParams();
  const [id, setid] = useState({ id: params.id });
  const [Data, SetData] = useState({
    id: "",
    state: "",
    city: "",
    petrol_price: "",
    diesel_price: "",
    cng_price: "",
    lpg_price: "",
    date: "",
  });
  const [options, setOptions] = useState([])
  const Dropdown_Name = async () => {
    const Result = await API.post("/get_allprice", {}, { headers: { authorization: `${token}` } })
    DropDownArr = []
    reloadId = []
    Result.data.data.map((val, i) => {
      DropDownArr.push({ label: val.city, value: val.id })
      reloadId.push(val.id)
    })
    setOptions(DropDownArr)
  }

  const Getview = async (Eid) => {
    const result = await API.post(`/get_single_fuelprice/${Eid !== "" ? Eid : id.id}`, {}, { headers: { authorization: `${token}` } });
    SetData({
      id: result.data.data.id,
      state: result.data.data.state,
      city: result.data.data.city,
      petrol_price: result.data.data.petrol_price,
      diesel_price: result.data.data.diesel_price,
      lpg_price: result.data.data.lpg_price,
      cng_price: result.data.data.cng_price,
      date: result.data.data.date,

    });
  };

  const selectpickerData = (e) => {
    setid({ id: e });
    Getview(e);
  };

  useEffect(() => {
    Dropdown_Name()
    Getview("")
  }, [])

  const VirtualizedSelect = ({ options, ...rest }) => {

    return (
      <Select
        {...rest}
        className="customMulSelect wv-250"
        classNamePrefix="react-select"
        components={{ MenuList: VirtualizedMenuList }}
        options={options}
        onChange={(e) => selectpickerData(e.value)}
        value={options?.find((item) => item.value == id.id)}
      />
    );
  };

  const VirtualizedMenuList = ({ children, maxHeight, getValue }) => {
    const selectedOption = getValue()[0];
    const selectedIndex = options.indexOf(selectedOption);

    return (
      <FixedSizeList height={maxHeight} itemCount={options.length} itemSize={35} width="100%">
        {({ index, style }) => (
          <div style={{ ...style }}>
            {children[index]}
          </div>
        )}
      </FixedSizeList>
    );
  };
  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3><Link to="/fuel-price" className='btn btn-primary btn-icon-lg me-3'><i className='bx bxs-left-arrow-alt'></i></Link>Fuel Price View</h3>
        <div className="page-heading-right">
          <VirtualizedSelect options={options} className="wv-200" />
          <Link to={`/fuel-price/edit/${id.id}`}>
            <Button variant="primary ms-3 my-1" value="edit">Edit</Button>
          </Link>
        </div>
      </div>
      <div className='page-content'>
        <Card>
          <Card.Body>
            <Row>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>State Name</p><span>{Data.state}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>City Name</p><span>{Data.city}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Petrol Price</p><span>{Data.petrol_price}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Diesel Price</p><span>{Data.diesel_price}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>CNG Price</p><span>{Data.cng_price}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>LPG Price</p><span>{Data.lpg_price}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Date</p><span>{Data.date}</span>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    </Layout>
  )
}

export default FuelPriceView