import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Bar, Doughnut } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import {
    ArcElement,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Filler,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
    defaults
} from 'chart.js';
import Layout from '../layout/Layout';
import { getDashboardData, getNewsCategoriesCount, getServiceSentereCount, getVehicleDetailCount } from '../service/apis';
import { object } from 'yup';

defaults.font.family = 'Maven Pro';
defaults.font.size = 14

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    ArcElement,
    LineElement,
    Filler,
    Title,
    Tooltip,
    Legend
);




const Home = () => {
    const [allDashboardCount, setAllDashboardCount] = useState([]);
    const [serviceSentereCount, setServiceSentereCount] = useState([]);
    const [vehicleDetailCount, setVehicleDetailCount] = useState([]);
    const [newsCategoriesCount, setNewsCategoriesCount] = useState([]);
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        fetchDashboardData()
        fetchServiceSentereCount()
        fetchVehicleDetailCount()
        fetchNewsCategoriesCount()
    }, [])

    const fetchDashboardData = async () => {
        setIsLoading(true);
        try {
            const response = await getDashboardData();
            setAllDashboardCount(response?.data?.Data);
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    };
    const fetchServiceSentereCount = async () => {
        setIsLoading(true);
        try {
            const response = await getServiceSentereCount();
            response.data.data &&
                setServiceSentereCount(response.data.data);
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    };
    const fetchVehicleDetailCount = async () => {
        setIsLoading(true);
        try {
            const response = await getVehicleDetailCount();
            response.data.data &&
                setVehicleDetailCount(response.data.data);
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    };
    const fetchNewsCategoriesCount = async () => {
        setIsLoading(true);
        try {
            const response = await getNewsCategoriesCount();
            response.data.data &&
                setNewsCategoriesCount(response.data.data);
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    };



    // Service Center
    const keysArray = Object?.keys(serviceSentereCount);
    const valuesArray = Object?.values(serviceSentereCount);
    const ServiceCenterData = {
        labels: keysArray,
        datasets: [
            {
                label: 'Service Center',
                data: valuesArray,
                barThickness: 12,
                backgroundColor: '#DB73FF',
            }],
    };
    const ServiceCenterOptions = {
        responsive: true,
        plugins: {
            title: {
                display: false,
            },
            legend: {
                display: false,
            },
        },
        scales: {
            y: {
                ticks: {
                    stepSize: 20,
                },
                grid: {
                    display: true,
                },
                scaleLabel: {
                    display: true,
                }
            },
            x: {
                grid: {
                    display: false,
                },
                scaleLabel: {
                    display: true,
                }
            }
        }
    };


    // Vehicle Details
    const VehicleDetailskeys = Object?.keys(vehicleDetailCount);
    const VehicleDetailsValues = Object?.values(vehicleDetailCount);
    const VehicleDetailsData = {
        labels: VehicleDetailskeys,
        datasets: [
            {
                label: 'Vehicle Details',
                data: VehicleDetailsValues,
                backgroundColor: ['#1FD9A3', '#FFB15C', '#6a9bf4', '#FF5C84', '#DB73FF', '#0093ED'],
                borderColor: ['#1FD9A3', '#FFB15C', '#6a9bf4', '#FF5C84', '#DB73FF', '#0093ED']
            },
        ],
    };
    const VehicleDetailsOptions = {
        responsive: true,
        plugins: {
            title: {
                display: false,
            },
            legend: {
                display: true,
                position: "bottom",
                labels: {
                    boxWidth: 10,
                    boxHeight: 10,
                    usePointStyle: true,
                    pointStyle: 'rectRounded',
                },
            },
        },
    };

    // News

    const newsCountkeys = Object?.keys(newsCategoriesCount);
    const newsCountValues = Object?.values(newsCategoriesCount);
    const NewsData = {
        labels: newsCountkeys,
        datasets: [
            {
                label: 'News',
                data: newsCountValues,
                barThickness: 12,
                backgroundColor: '#1FD9A3',
            }],
    };
    const NewsOptions = {
        responsive: true,
        plugins: {
            title: {
                display: false,
            },
            legend: {
                display: false,
            },
        },
        scales: {
            y: {
                ticks: {
                    stepSize: 500,
                },
                grid: {
                    display: true,
                },
                scaleLabel: {
                    display: true,
                }
            },
            x: {
                grid: {
                    display: false,
                },
                scaleLabel: {
                    display: true,
                }
            }
        }
    };
    return (
        <Layout sidebar={true}>
            <div className="vv-dashboard">
                <Row className='mb-4 g-4'>
                    <Col xxl={3} xl={4} md={6}>
                        <Card>
                            <Card.Body>
                                <div className="counter orange">
                                    <div className="counter-media">
                                        <i className='bx bxs-car'></i>
                                    </div>
                                    <div className="counter-content">
                                        <h3>{allDashboardCount?.VehicleinformationCount}+</h3>
                                        <p>Vehicle Information</p>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xxl={3} xl={4} md={6}>
                        <Card>
                            <Card.Body>
                                <div className="counter pink">
                                    <div className="counter-media">
                                        <i className='bx bxs-wrench'></i>
                                    </div>
                                    <div className="counter-content">
                                        <h3>{allDashboardCount?.ServiceCityCount}+</h3>
                                        <p>Service Center</p>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xxl={3} xl={4} md={6}>
                        <Card>
                            <Card.Body>
                                <div className="counter green">
                                    <div className="counter-media">
                                        <i className='bx bxs-bus-school'></i>
                                    </div>
                                    <div className="counter-content">
                                        <h3>{allDashboardCount?.DrivingschoolCount}+</h3>
                                        <p>Driving School</p>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xxl={3} xl={4} md={6}>
                        <Card>
                            <Card.Body>
                                <div className="counter blue">
                                    <div className="counter-media">
                                        <i className='bx bxs-school'></i>
                                    </div>
                                    <div className="counter-content">
                                        <h3>{allDashboardCount?.AffiliationcityCount}+</h3>
                                        <p>Affilation Service City</p>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className='g-4 mb-4'>
                    <Col xxl={2} xl={3} lg={4} md={6}>
                        <Card className='h-100'>
                            <Card.Body>
                                <div className="attch-link green">
                                    <div className="attch-link-title">
                                        <i className='bx bxs-credit-card-front'></i>
                                        <h4>RC Information</h4>
                                    </div>
                                    <div className="attch-link-content">
                                        <ul>
                                            <li><Link to="/dl-rc-info">DL / RC Info</Link></li>
                                            <li><Link to="/traffic-state">Traffic State</Link></li>
                                            <li><Link to="/traffic-rule">Traffic Rules</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xxl={2} xl={3} lg={4} md={6}>
                        <Card className='h-100'>
                            <Card.Body>
                                <div className="attch-link red">
                                    <div className="attch-link-title">
                                        <i className='bx bxs-user'></i>
                                        <h4>Affiliation</h4>
                                    </div>
                                    <div className="attch-link-content">
                                        <ul>
                                            <li><Link to="/affiliation-dashboard">Affiliation Dashboard</Link></li>
                                            <li><Link to="/affiliation">Affiliation Program</Link></li>
                                            <li><Link to="/affiliation-city">All Services City List</Link></li>
                                            <li><Link to="/service-provider-city">Services Provider City</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xxl={2} xl={3} lg={4} md={6}>
                        <Card className='h-100'>
                            <Card.Body>
                                <div className="attch-link orange">
                                    <div className="attch-link-title">
                                        <i className='bx bxs-news'></i>
                                        <h4>News</h4>
                                    </div>
                                    <div className="attch-link-content">
                                        <ul>
                                            <li><Link to="/news-category">News Category</Link></li>
                                            <li><Link to="/news-headline">News Headlines</Link></li>
                                            <li><Link to="/news">News</Link></li>
                                            <li><Link to="/tags">Tags</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xxl={2} xl={3} lg={4} md={6}>
                        <Card className='h-100'>
                            <Card.Body>
                                <div className="attch-link purple">
                                    <div className="attch-link-title">
                                        <i className='bx bxs-bus-school'></i>
                                        <h4>Driving School</h4>
                                    </div>
                                    <div className="attch-link-content">
                                        <ul>
                                            <li><Link to="/driving-school-state">Driving School State</Link></li>
                                            <li><Link to="/driving-school-city">Driving School City</Link></li>
                                            <li><Link to="/driving-school-area">Driving School Area</Link></li>
                                            <li><Link to="/driving-school-details">Driving School Details</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xxl={2} xl={3} lg={4} md={6}>
                        <Card className='h-100'>
                            <Card.Body>
                                <div className="attch-link pink">
                                    <div className="attch-link-title">
                                        <i className='bx bxs-wrench'></i>
                                        <h4>Service Center</h4>
                                    </div>
                                    <div className="attch-link-content">
                                        <ul>
                                            <li><Link to="/service-center-state">State</Link></li>
                                            <li><Link to="/service-center-city">City</Link></li>
                                            <li><Link to="/service-center-brand">Brand</Link></li>
                                            <li><Link to="/service-center">Service Center</Link></li>
                                            <li><Link to="/service-dealer">Service Dealer</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xxl={2} xl={3} lg={4} md={6}>
                        <Card className='h-100'>
                            <Card.Body>
                                <div className="attch-link blue">
                                    <div className="attch-link-title">
                                        <i className='bx bxs-car'></i>
                                        <h4>Vehicle Info 2.0</h4>
                                    </div>
                                    <div className="attch-link-content">
                                        <ul>
                                            <li><Link to="/vehicle-category">Category</Link></li>
                                            <li><Link to="/vehicle-brand">Brand</Link></li>
                                            <li><Link to="/vehicle-information">Vehicle Information</Link></li>
                                            <li><Link to="/vehicle-body-type">Body Type</Link></li>
                                            <li><Link to="/vehicle-key-specification">Key Specification</Link></li>
                                            <li><Link to="/vehicle-variant-specification">Veriant Specification</Link></li>
                                            <li><Link to="/vehicle-compare">Compares</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className='g-4'>
                    <Col xxl={6} lg={6} md={12}>
                        <Card>
                            <Card.Body>
                                <div className="chart-title">
                                    <h4>Service Center</h4>
                                </div>
                                <Bar options={ServiceCenterOptions} data={ServiceCenterData} height="136" />
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xxl={3} lg={3} md={6}>
                        <Card>
                            <Card.Body>
                                <div className="chart-title">
                                    <h4>Vehicle Details</h4>
                                </div>
                                <Doughnut options={VehicleDetailsOptions} data={VehicleDetailsData} />
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xxl={3} lg={3} md={6}>
                        <Card>
                            <Card.Body>
                                <div className="chart-title">
                                    <h4>News</h4>
                                </div>
                                <Bar options={NewsOptions} data={NewsData} height="300" />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Layout>
    )
}

export default Home