import React, { useContext, useEffect, useState } from 'react';
import Layout from "../../layout/Layout";
import { Card, Row, Col, Button, Badge } from "react-bootstrap";
import SelectPicker from 'rsuite/SelectPicker';
import { Link, useNavigate, useParams } from "react-router-dom";
import { deleteServiceCity, deleteServiceProviderCity, getServiceProviderDropdown, viewServiceProviderCity } from '../../service/apis';
import Swal from "sweetalert2";
import $ from "jquery";
import { toast } from 'react-toastify';
import { AuthContext } from '../../AppContext';


const ServiceProviderCityView = () => {
    const { userPermission } = useContext(AuthContext)
    const [isLoading, setIsLoading] = useState()
    const [serviceProviderCity, setServiceProviderCity] = useState()
    const [serviceProviderDropdownCity, setServiceProviderDropdownCity] = useState([])
    const params = useParams()
    const navigate=useNavigate()
    useEffect(() => {
        fetchServiceProviderDropdown()
    }, [])

    useEffect(() => {
        fetchViewServiceProviderCity()
    }, [params?.id])



    //Filter 
    const selectCity = serviceProviderDropdownCity?.map(
        item => ({ label: item?.city, value: item?.id })
    );


    //api 
    const fetchServiceProviderDropdown = async () => {
        setIsLoading(true);
        try {
            const response = await getServiceProviderDropdown()
            setServiceProviderDropdownCity(response?.data?.data?.affilation_city)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    }
    const fetchViewServiceProviderCity = async () => {
        setIsLoading(true);
        try {
            const response = await viewServiceProviderCity(params.id)
            setServiceProviderCity(response?.data?.data)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    }
    let count = 10
    let swalCountdownInterval
    const handleDelete = async () => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: "btn btn-danger btn-lg counter",
                cancelButton: "btn btn-primary btn-lg me-3",
            },
            buttonsStyling: false,
        });

        swalWithBootstrapButtons
            .fire({
                title: "Are you sure you want to delete?",
                text: "You won't be able to revert this!",
                imageUrl: '../../icon/alert.svg',
                imageWidth: 80,
                imageHeight: 80,
                confirmButtonText: "OK (10)",
                cancelButtonText: "Cancel",
                showCancelButton: true,
                reverseButtons: true,
                didOpen: () => {
                    $(".swal2-confirm").attr('disabled', true);
                    swalCountdownInterval = setInterval(function () {
                        count--
                        if (count < 1) {
                            $(".counter").text(`OK`)
                            $(".swal2-confirm").attr('disabled', false);
                            clearInterval(swalCountdownInterval)
                        } else {
                            $(".counter").text(`OK (${count})`)
                        }
                    }, 1000);
                }
            })
            .then(async (result) => {
                if (result.isConfirmed) {
                    setIsLoading(true);
                    try {
                        deleteServiceProviderCity(params.id).then(() => navigate(`/service-provider-city`));
                    
                    } catch (error) {
                        console.log(error)
                    } finally {
                        setIsLoading(false);
                    }
                } else {
                    count = 10
                    clearInterval(swalCountdownInterval)
                }
            });
    };

    const handleDeleteCity=(cityId)=>{
        setIsLoading(true);
        try {
            deleteServiceCity({city_id:cityId.toString(),service_city_id:params.id}).then(()=>{fetchViewServiceProviderCity();toast.success("City Delete Successfully")})
        
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    }
    return (
        <>
            <Layout sidebar={true}>
                {isLoading && <div className="loader" ></div>}
                <div className="page-heading">
                    <h3><Link to="/service-provider-city" className='btn btn-primary btn-icon-lg me-3'><i className='bx bxs-left-arrow-alt'></i></Link>View Service Provider City </h3>
                    <div className="page-heading-right">
                        <SelectPicker
                            cleanable={false}
                            data={selectCity}
                            onChange={(e)=>navigate(`/service-provider-city/view/${e}`)}
                            value={serviceProviderCity?.id}
                            className="wv-200 my-1 ms-3"
                            placeholder="Select Service Provider"
                            placement="bottomEnd"
                        />
                        {userPermission["Service-Provider"]?.edit == 1 &&<Link to={`/service-provider-city/edit/${params.id}`} className="btn btn-primary ms-3 my-1">Edit</Link>}
                        {userPermission["Service-Provider"]?.delete == 1 &&<Button variant="danger ms-3 my-1 btn-icon-lg" onClick={handleDelete} type="button"><i className="bx bx-trash-alt"></i></Button>}
                    </div>
                </div>
                <div className='page-content'>
                    <Card>
                        <Card.Body>
                            <Row className="g-4">
                                <Col md={4}>
                                    <div>
                                        <p className='mb-0 fw-bold'>Services Providers</p><span>{serviceProviderCity?.get_service_provider_name?.provider}</span>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <p className='mb-0 fw-bold'>Services</p><span>{serviceProviderCity?.get_affiliation_services?.services}</span>
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div>
                                        <p className='mb-2 fw-bold'>City Name</p>
                                        <div className='d-flex flex-wrap'>
                                        {(serviceProviderCity?.city_id == "ALL" || serviceProviderCity?.city_id == "All" || serviceProviderCity?.city_id == "all") 
                                            ?
                                                <Badge bg="primary" className="wv-100 dot-line-max-12">
                                                    {serviceProviderCity.city_id}
                                                </Badge>
                                            :
                                            serviceProviderCity?.city_name?.map((item) => {
                                            return (
                                                <div className='badge-view d-flex align-items-center justify-content-between me-1 mb-1'>
                                                    <Badge bg="primary" className="wv-100 dot-line-max-12">
                                                        {item.city}
                                                    </Badge>
                                                   {userPermission["Service-Provider"]?.edit == 1 && <i className='bx bx-x bg-danger text-white' onClick={()=>handleDeleteCity(item.id)}></i>}
                                                </div>
                                                )
                                            })
                                        }
                                        {/* {serviceProviderCity?.city_name?.map((item) => {
                                        return (
                                                <div className='badge-view d-flex align-items-center justify-content-between me-1 mb-1'>
                                                    <Badge bg="primary" className="wv-100 dot-line-max-12">
                                                        {item.city}
                                                    </Badge>
                                                    <i className='bx bx-x bg-danger text-white' onClick={()=>handleDeleteCity(item.id)}></i>
                                                </div>)
                                        })} */}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </div>
            </Layout>
        </>
    )
};

export default ServiceProviderCityView;