import React, { useState } from "react";
import { Row, Col, Card, Button, Form, Breadcrumb } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import Layout from '../../layout/Layout';
import Switch from 'react-switch'
import { API } from "../../App";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

const RCDLinfoAdd = () => {
    const token = Cookies.get("fmljwt");
    const navigate = useNavigate()
    const [validated, setvalidated] = useState(false)
    const [isLoading, setIsLoading] = useState(false);

    const [AddData, setAddData] = useState([{
        type: "",
        title: "",
        status: "",
    }])

    const [AddImage, setAddImage] = useState([{
        thumb_image: "",
        en: "",
        hi: "",
        mr: "",
        gu: "",
        kn: "",
        ta: "",
        te: "",
        bn: "",
        ml: "",
        or: "",
        pa: ""
    }])

    const addReturn = () => {
        const returnTag = {
            type: "",
            title: "",
        }
        const returnTagicon = {
            thumb_image: "",
            en: "",
            hi: "",
            mr: "",
            gu: "",
            kn: "",
            ta: "",
            te: "",
            bn: "",
            ml: "",
            or: "",
            pa: "",
        }
        setAddData([...AddData, returnTag])
        setAddImage([...AddImage, returnTagicon])
    }
    const removeReturn = (index) => {
        const data = [...AddData]
        data.splice(index, 1)
        setAddData(data)

        const data1 = [...AddImage]
        data1.splice(index, 1)
        setAddImage(data1)
    }

    const returnChange = (e, index) => {
        let data = [...AddData];
        data[index][e.target.name] = e.target.value
        setAddData(data)
    }

    const returnStatus = (e, index) => {
        let data = [...AddData];
        data[index]['status'] = e == true ? 1 : 0
        setAddData(data)
    }

    const returnChangeIcon = (e, index) => {
        let data = [...AddImage];
        data[index][e.target.name] = e.target.files[0]
        setAddImage(data)
    }

    const submiteData = async () => {
        let count = 0
        console.log('AddImage[0].en >>>', AddImage[0].en)
        AddData.map((val, ind) => {
            if (val.type === "" || val.title === "") {
                count++
            }
        })
        if (count !== 0 || AddImage[0].en == "" || AddImage[0].hi == "" || AddImage[0].en == "" || AddImage[0].mr == "" || AddImage[0].gu == "" || AddImage[0].kn == "" || AddImage[0].ta == "" || AddImage[0].te == "" ||
            AddImage[0].bn == "" || AddImage[0].ml == "" || AddImage[0].or == "" || AddImage[0].pa == "") {
            setvalidated(true)
        } else {
            const Form = new FormData()
            Form.append('thumb_image', AddImage[0].thumb_image)
            Form.append('en', AddImage[0].en)
            Form.append('hi', AddImage[0].hi)
            Form.append('mr', AddImage[0].mr)
            Form.append('gu', AddImage[0].gu)
            Form.append('kn', AddImage[0].kn)
            Form.append('ta', AddImage[0].ta)
            Form.append('te', AddImage[0].te)
            Form.append('bn', AddImage[0].bn)
            Form.append('ml', AddImage[0].ml)
            Form.append('or', AddImage[0].or)
            Form.append('pa', AddImage[0].pa)
            Form.append('data', JSON.stringify(AddData))

            // AddImage.map((dataVal, ind) => {
            //     Form.append(`thumb_image-${ind}`, dataVal.thumb_image)
            //     Form.append(`en-${ind}`, dataVal.en)
            //     Form.append(`hi-${ind}`, dataVal.hi)
            //     Form.append(`mr-${ind}`, dataVal.mr)
            //     Form.append(`gu-${ind}`, dataVal.gu)
            //     Form.append(`kn-${ind}`, dataVal.kn)
            //     Form.append(`ta-${ind}`, dataVal.ta)
            //     Form.append(`te-${ind}`, dataVal.te)
            //     Form.append(`bn-${ind}`, dataVal.bn)
            //     Form.append(`ml-${ind}`, dataVal.ml)
            //     Form.append(`or-${ind}`, dataVal.or)
            //     Form.append(`pa-${ind}`, dataVal.pa)
            // })
            setIsLoading(true);
            const result = await API.post("/store_rc_dl_detail", Form, { headers: { authorization: `${token}` } });
            if (result.data === "Title Allready Exies") {
                toast.error("Title Allrady Exits");
                setIsLoading(false);
            } else {
                setIsLoading(false);
                toast.success("Data Saved successfully");
                navigate("/dl-rc-info");
            }
        }
    }

    return (
        <Layout sidebar={true}>
            {isLoading && <div className="loader"></div>}
            <div className="page-heading">
                <h3>DL / RC Info Add</h3>
                <Breadcrumb className="d-none d-sm-none d-md-none d-lg-block">
                    <Breadcrumb.Item >
                        <Link to="/"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item >
                        <Link to="/dl-rc-info">RC-DL Info</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>RC-DL Info Add</Breadcrumb.Item>
                </Breadcrumb>
            </div>

            <div className="page-content">
                <Form noValidate validated={validated}>
                    {AddData.map((detail, i) => (
                        <Card className="mb-4">
                            <Card.Body>
                                <Row>
                                    <Col md={4}>
                                        <Form.Label htmlFor="type">Type</Form.Label>
                                        <Form.Select name='type' className="my-2" onChange={(e) => returnChange(e, i)} required>
                                            <option value={""}>Select</option>
                                            <option value={1}>DL</option>
                                            <option value={2}>RC</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Type Field Is Require
                                        </Form.Control.Feedback>
                                    </Col>
                                    <Col md={8}>
                                        <Form.Label htmlFor="title">Title</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="title"
                                            className="my-2"
                                            onChange={(e) => returnChange(e, i)}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Title Field Is Require
                                        </Form.Control.Feedback>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label htmlFor="english">English</Form.Label>
                                        <Form.Control
                                            type="file"
                                            name="en"
                                            className="my-2"
                                            onChange={(e) => returnChangeIcon(e, i)}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            English Field Is Require
                                        </Form.Control.Feedback>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label htmlFor="hindi">Hindi</Form.Label>
                                        <Form.Control
                                            type="file"
                                            name="hi"
                                            className="my-2"
                                            onChange={(e) => returnChangeIcon(e, i)}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Hindi Field Is Require
                                        </Form.Control.Feedback>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label htmlFor="marathi">Marathi</Form.Label>
                                        <Form.Control
                                            type="file"
                                            name="mr"
                                            className="my-2"
                                            onChange={(e) => returnChangeIcon(e, i)}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Marathi Field Is Require
                                        </Form.Control.Feedback>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label htmlFor="gujarati">Gujarati</Form.Label>
                                        <Form.Control
                                            type="file"
                                            name="gu"
                                            className="my-2"
                                            onChange={(e) => returnChangeIcon(e, i)}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Gujarati Field Is Require
                                        </Form.Control.Feedback>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label htmlFor="tamil">Tamil</Form.Label>
                                        <Form.Control
                                            type="file"
                                            name="ta"
                                            className="my-2"
                                            onChange={(e) => returnChangeIcon(e, i)}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Tamil Field Is Require
                                        </Form.Control.Feedback>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label htmlFor="kannada">Kannada</Form.Label>
                                        <Form.Control
                                            type="file"
                                            name="kn"
                                            className="my-2"
                                            onChange={(e) => returnChangeIcon(e, i)}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Kannada Field Is Require
                                        </Form.Control.Feedback>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label htmlFor="telugu">Telugu</Form.Label>
                                        <Form.Control
                                            type="file"
                                            name="te"
                                            className="my-2"
                                            onChange={(e) => returnChangeIcon(e, i)}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Telugu Field Is Require
                                        </Form.Control.Feedback>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label htmlFor="bangali">Bangali</Form.Label>
                                        <Form.Control
                                            type="file"
                                            name="bn"
                                            className="my-2"
                                            onChange={(e) => returnChangeIcon(e, i)}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Bangali Field Is Require
                                        </Form.Control.Feedback>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label htmlFor="panjabi">Panjabi</Form.Label>
                                        <Form.Control
                                            type="file"
                                            name="pa"
                                            className="my-2"
                                            onChange={(e) => returnChangeIcon(e, i)}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Panjabi Field Is Require
                                        </Form.Control.Feedback>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label htmlFor="odisha">Odisha</Form.Label>
                                        <Form.Control
                                            type="file"
                                            name="or"
                                            className="my-2"
                                            onChange={(e) => returnChangeIcon(e, i)}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Odisha Field Is Require
                                        </Form.Control.Feedback>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label htmlFor="malyalam">Malyalam</Form.Label>
                                        <Form.Control
                                            type="file"
                                            name="ml"
                                            className="my-2"
                                            onChange={(e) => returnChangeIcon(e, i)}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Malyalam Field Is Require
                                        </Form.Control.Feedback>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label htmlFor="thumbimage">Thumb Image</Form.Label>
                                        <Form.Control
                                            type="file"
                                            name="thumb_image"
                                            className="my-2"
                                            onChange={(e) => returnChangeIcon(e, i)}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Thumb Image Field Is Require
                                        </Form.Control.Feedback>
                                    </Col>

                                    <Col md={2}>
                                        <Form.Label htmlFor="status" className="d-block">
                                            Status
                                        </Form.Label>
                                        <Switch
                                            onChange={(e) => returnStatus(e, i)}
                                            name="status"
                                            checked={detail.status}
                                            offColor="#C8C8C8"
                                            onColor="#0093ed"
                                            height={30}
                                            width={70}
                                            className="react-switch my-2"
                                            uncheckedIcon={
                                                <div className="react-switch-off">OFF</div>
                                            }
                                            checkedIcon={<div className="react-switch-on">ON</div>}
                                        />
                                    </Col>

                                    {/* <Col md={2} className="d-flex align-items-end justify-content-end">
                                        {
                                            i == 0 ?
                                                <Button
                                                    variant="primary"
                                                    className="btn-icon-lg"
                                                    onClick={addReturn}
                                                >
                                                    <i className='bx bx-plus'></i>
                                                </Button>
                                                :
                                                <Button
                                                    variant="danger"
                                                    className="btn-icon-lg"
                                                    onClick={() => removeReturn(i)}
                                                >
                                                    <i className='bx bx-minus' ></i>
                                                </Button>
                                        }
                                    </Col> */}
                                </Row>
                            </Card.Body>
                        </Card>
                    ))}
                    <Card>
                        <Card.Body className="text-end">
                            <Button variant="primary" onClick={submiteData} className="me-3">Save</Button>
                            <Link to='/dl-rc-info'>
                                <Button variant="secondary">Cancel</Button>
                            </Link>
                        </Card.Body>
                    </Card>
                </Form>
            </div>
        </Layout>
    )
}

export default RCDLinfoAdd