import React, { useEffect, useState } from "react";
import Layout from "../../layout/Layout";
import {
    Row,
    Col,
    Card,
    Button,
    Form,
    InputGroup,
    Breadcrumb,
} from "react-bootstrap";
import CreatableSelect from "react-select/creatable";
import { API } from "../../App";
import { Link, useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import Switch from "react-switch";
import Fancybox from "../../Component/FancyBox";
import { SelectPicker, Tree } from "rsuite";

const NewsHeadlineEdit = () => {
    const params = useParams();
    const token = Cookies.get("fmljwt");
    const navigate = useNavigate();
    const [suggetion, setsuggetion] = useState([]);
    const [validated, setvalidated] = useState(false);
    const [Category, setCategory] = useState();
    const [Brand_data, setBrand_data] = useState();
    const [opt, setopt] = useState([]);
    const [opt1, setopt1] = useState([]);
    const [opt2, setopt2] = useState([]);
    const [opt3, setopt3] = useState([]);
    const [news_categoryDropDown, setNews_categoryDropDown] = useState([]);
    const [NewData, setNewData] = useState({
        title: "",
        description: "",
        news_url: "",
        headtag: "",
        date: "",
        status: "",
        is_slider: "",
    });
    const [isLoading, setIsLoading] = useState(false);
    const handleOnchangeNewsCategory = async (e) => {
        setNews_categoryDropDown(
            Array.isArray(e) &&
            e?.map((item) => {
                return { value: item.value, label: item.label };
            })
        );
    };

    const Cate_hendler = async (e) => {
        setCategory(e);
        brand_onload(e);
    };
    const BrandHandler = async (e) => {
        setBrand_data(e);
    };
    // console.log('Brand_data >>>', Brand_data)
    const tag_hendler = async (e) => {
        setsuggetion(Array.isArray(e) &&
            e?.map((x) => {
                return { value: x.value, label: x.label };
            }));
    };

    const New_category_onload = async () => {
        const res = await API.post(
            "/get-news-headline-category-dropdown",
            {},
            { headers: { authorization: `${token}` } }
        );
        setopt(
            res?.data?.data?.map((item) => {
                return { value: item.id, label: item.name };
            })
        );
    };

    const category_onload = async () => {
        const res = await API.post(
            "/get-news-category-dropdown",
            {},
            { headers: { authorization: `${token}` } }
        );
        let abc = [{ value: 0, label: "All" }]
        res?.data?.data?.map((item) => {
            abc.push({ value: item.id, label: item.category_name });
        })
        setopt1(abc
        );
    };

    const brand_onload = async (id) => {
        const res = await API.post(
            `/get-news-brand-dropdown`,
            { id: id },
            { headers: { authorization: `${token}` } }
        );
        let abc = []
        id != 0 ? res?.data?.data?.map((item) => {
            abc.push({ value: item.id, label: item.name });
        }) : abc.push({ value: 0, label: "All" })
        setopt2(
            abc
        );
    };
    const Tag_onload = async () => {
        const res = await API.post(
            "/get-all-tag",
            {},
            { headers: { authorization: `${token}` } }
        );
        setopt3(res?.data?.data?.map((item) => {
            return { value: item.id, label: item.name };
        }));
    };
    console.log('new++++++++++++++++++', process.env.REACT_APP_NEWS_URL)
    const Get_view = async () => {
        if (params?.id) {
            console.log("inside get view")
            const result = await API.post(
                `/get-news-headline/${params.id}`,
                {},
                { headers: { authorization: `${token}` } }
            );
            if (result.data.status === true) {
                setNewData({
                    id: result.data.data.id,
                    title: result.data.data.title,
                    description: result.data.data.description,
                    news_url: result.data.data.news_url.replace(`${process.env.REACT_APP_NEWS_URL}`, ''),
                    headtag: result.data.data.headtag,
                    date: result.data.data.date.split("T")[0],
                    image: result.data.data.image,
                    websiteimage: result.data.data.websiteimage,
                    status: result.data.data.status,
                    is_slider: result.data.data.is_slider,
                });

                // setNews_categoryDropDown();
                let abc = [];
                result?.data?.data?.newsHeadlineCategory?.map((item) => {
                    abc.push(
                        { value: item.id, label: item.name }
                    )
                })
                setNews_categoryDropDown(abc);


                // if (result?.data?.data?.newsVehicleCategory != null) {
                setCategory(result?.data?.data?.vehicle_category_id)
                brand_onload(result?.data?.data?.vehicle_category_id);
                // }

                // if (result?.data?.data?.newsVehicleBrands != null) {
                setBrand_data(result?.data?.data?.brand_id)
                // }

                if (result?.data?.data?.newsTags != null) {
                    setsuggetion(result?.data?.data?.newsTags?.map(item => {
                        return { value: item.id, label: item.name }
                    }))
                }
                Tag_onload();
            }
        } else {
            setNewData({
                id: "",
                title: "",
                description: "",
                news_url: "",
                headtag: "",
                date: "",
                image: "",
                websiteimage: "",
                status: "",
                is_slider: "",
            });
        }
        Tag_onload();
    };

    const SaveData = async (e) => {
        setNewData({ ...NewData, [e.target.name]: e.target.value });
    };
    const [image, setimage] = useState("");
    const image_hendler = (e) => {
        setimage(e.target.files[0]);
    };
    const [websiteimage, setwebsiteimage] = useState("");
    const image_hendler1 = (e) => {
        setwebsiteimage(e.target.files[0]);
    };

    const Statushendler = (e) => {
        const Result = e === true ? 1 : 0;
        setNewData({
            title: NewData.title,
            description: NewData.description,
            news_url: NewData.news_url,
            headtag: NewData.headtag,
            image: NewData.image,
            websiteimage: NewData.websiteimage,
            date: NewData.date.split("T")[0],
            is_slider: NewData.is_slider,
            status: Result,
        });
    };

    const Silderhendler = (e) => {
        const Result = e === true ? 1 : 0;
        setNewData({
            title: NewData.title,
            description: NewData.description,
            news_url: NewData.news_url,
            headtag: NewData.headtag,
            image: NewData.image,
            websiteimage: NewData.websiteimage,
            date: NewData.date.split("T")[0],
            status: NewData.status,
            is_slider: Result,
        });
    };
    console.log('tagsArray --->', suggetion)
    const Submit = async () => {
        setIsLoading(true);
        try {
            setvalidated(false)
            let Form = new FormData();
            let newsCategory = news_categoryDropDown?.map((val, ind) => val.value);
            let tagsArray = suggetion?.map((val, ind) => val.value)
            if (params.id) {
                if (NewData.title == "" ||
                    NewData.description == "" ||
                    NewData.news_url == "" ||
                    NewData.date == "") {
                    setvalidated(true)
                } else {
                    setvalidated(false)
                    Form.append("title", NewData.title);
                    Form.append("description", NewData.description);
                    Form.append("news_url", NewData.news_url);
                    Form.append("headtag", NewData.headtag);
                    Form.append("date", NewData.date);
                    Form.append("category_id", JSON.stringify(newsCategory));
                    Form.append("vehicle_category_id", Category);
                    Form.append("brand_id", Brand_data);
                    Form.append("tag_id", JSON.stringify(tagsArray));
                    Form.append("is_slider", NewData.is_slider || 0);
                    Form.append("status", NewData.status);
                    Form.append("image", image);
                    Form.append("websiteimage", websiteimage);
                    const result = await API.post(
                        `/update-news-headline/${params.id}`,
                        Form,
                        { headers: { authorization: `${token}` } }
                    );
                    if (result) {
                        toast.success("Data Update successfully");
                        navigate(`/news-headline/view/${params.id}`);
                    }
                }
            } else {
                if (NewData.title == "" ||
                    NewData.description == "" ||
                    NewData.news_url == "" ||
                    NewData.date == "") {
                    setvalidated(true)
                } else {
                    setvalidated(false)
                    Form.append("title", NewData.title)
                    Form.append("description", NewData.description)
                    Form.append("news_url", NewData.news_url)
                    Form.append("headtag", NewData.headtag)
                    Form.append("date", NewData.date)
                    Form.append("category_id", JSON.stringify(newsCategory));
                    Form.append("vehicle_category_id", Category);
                    Form.append("brand_id", Brand_data);
                    Form.append("tag_id", JSON.stringify(tagsArray));
                    Form.append("is_slider", NewData.is_slider || 0);
                    Form.append("status", NewData.status);
                    Form.append("image", image);
                    Form.append("websiteimage", websiteimage);
                    const result = await API.post("/create-news-headline", Form, { headers: { authorization: `${token}` } })
                    if (result) {
                        toast.success("Data Saved successfully");
                        navigate("/news-headline")
                    }
                }
            }
            setIsLoading(false);
        } catch (error) {

            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }

    };
    useEffect(() => {
        Get_view();
        New_category_onload();
        category_onload();
    }, []);
    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                {isLoading && <div className="loader" ></div>}
                <h3>News Headline {params.id ? "Edit" : "Add"}</h3>
                <Breadcrumb className="d-none d-sm-none d-md-none d-lg-block">
                    <Breadcrumb.Item>
                        <Link to="/">
                            <i className="bx bx-home-alt me-2 fs-5"></i> Home
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/news-headline">News Headline </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active> News Headline {params.id ? "Edit" : "Add"}</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className="page-content">
                <Form noValidate validated={validated}>
                    <Card className="mb-4">
                        <Card.Body>
                            <Row>
                                <Col md={4}>
                                    <Form.Label htmlFor="newscategory">News Category</Form.Label>
                                    <CreatableSelect
                                        isMulti
                                        name="newscategory"
                                        defaultValue={news_categoryDropDown}
                                        placeholder="Select News Category"
                                        className="customMulSelect my-2"
                                        classNamePrefix="react-select"
                                        closeMenuOnSelect={false}
                                        onChange={handleOnchangeNewsCategory}
                                        value={news_categoryDropDown && news_categoryDropDown}
                                        required
                                        options={opt}
                                    />
                                </Col>

                                <Col md={4}>
                                    <Form.Label htmlFor="icon">Category</Form.Label>
                                    <SelectPicker
                                        cleanable={false}
                                        data={opt1}
                                        name="vehicle_category_id"
                                        searchable={false}
                                        value={Category}
                                        className="my-2"
                                        block
                                        placeholder="Select Category"
                                        onChange={(e) => Cate_hendler(e)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        State Code Field Is Require
                                    </Form.Control.Feedback>
                                </Col>

                                <Col md={4}>
                                    <Form.Label htmlFor="icon">Brand</Form.Label>
                                    <SelectPicker
                                        cleanable={false}
                                        data={opt2}
                                        name="brand_id"
                                        searchable={false}
                                        value={Brand_data}
                                        className="my-2"
                                        block
                                        placeholder="Select Brand"
                                        onChange={(e) => BrandHandler(e)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        State Code Field Is Require
                                    </Form.Control.Feedback>
                                </Col>

                                <Col md={6}>
                                    <Form.Label htmlFor="tag">Tag</Form.Label>
                                    <CreatableSelect
                                        isMulti
                                        name="tag_suggestion"
                                        defaultValue={suggetion}
                                        className="customMulSelect my-2"
                                        classNamePrefix="react-select"
                                        closeMenuOnSelect={false}
                                        onChange={tag_hendler}
                                        value={suggetion && suggetion}
                                        options={opt3}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Form.Label htmlFor="title">Title</Form.Label>
                                    <Form.Control
                                        type="text"
                                        className="my-2"
                                        name="title"
                                        required
                                        value={NewData.title}
                                        onChange={SaveData}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Title Field Is Require
                                    </Form.Control.Feedback>
                                </Col>

                                <Col md={6}>
                                    <Form.Label htmlFor="description">Description</Form.Label>
                                    <Form.Control
                                        type="text"
                                        className="my-2"
                                        name="description"
                                        required
                                        value={NewData.description}
                                        onChange={SaveData}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Description Field Is Require
                                    </Form.Control.Feedback>
                                </Col>

                                <Col md={6}>
                                    <Form.Label htmlFor="newsurl">News URL</Form.Label>
                                    <Form.Control
                                        type="text"
                                        className="my-2"
                                        name="news_url"
                                        required
                                        value={NewData.news_url}
                                        onChange={SaveData}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        News URL Field Is Require
                                    </Form.Control.Feedback>
                                </Col>

                                <Col md={12}>
                                    <Form.Label htmlFor="headnote">
                                        Head Note: Headtag Given By SEO
                                    </Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        type="textarea"
                                        className="my-2"
                                        name="headtag"
                                        required
                                        value={NewData.headtag}
                                        onChange={SaveData}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Head Note Field Is Require
                                    </Form.Control.Feedback>
                                </Col>
                                <Col md={2}>
                                    <Form.Label htmlFor="date">Date</Form.Label>
                                    <Form.Control
                                        type="date"
                                        className="my-2"
                                        name="date"
                                        required
                                        value={NewData.date}
                                        onChange={SaveData}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Date Field Is Require
                                    </Form.Control.Feedback>
                                </Col>
                                <Col md={3}>
                                    <Form.Label htmlFor="image"> Image</Form.Label>
                                    <InputGroup className="my-2">
                                        <Form.Control
                                            type="file"
                                            name="image"
                                            onChange={image_hendler}
                                        />
                                        <InputGroup.Text>
                                            <Fancybox>
                                                <a data-fancybox="gallery" href={NewData.image}>
                                                    <img
                                                        src={NewData.image}
                                                        className="hv-30 rounded-3"
                                                        alt=""
                                                    />
                                                </a>
                                            </Fancybox>
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Col>
                                <Col md={3}>
                                    <Form.Label htmlFor="websiteimage">Website Image</Form.Label>
                                    <InputGroup className="my-2">
                                        <Form.Control
                                            type="file"
                                            name="websiteimage"
                                            onChange={image_hendler1}
                                        />
                                        <InputGroup.Text>
                                            <Fancybox>
                                                <a data-fancybox="gallery" href={NewData.websiteimage}>
                                                    <img
                                                        src={NewData.websiteimage}
                                                        className="hv-30 rounded-3"
                                                        alt=""
                                                    />
                                                </a>
                                            </Fancybox>
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Col>

                                <Col md={2}>
                                    <Form.Label htmlFor="issilder" className="d-block">
                                        is Slider
                                    </Form.Label>
                                    <Switch
                                        onChange={Silderhendler}
                                        name="is_slider"
                                        checked={NewData.is_slider === 1 ? true : false}
                                        offColor="#C8C8C8"
                                        onColor="#0093ed"
                                        height={30}
                                        width={70}
                                        className="react-switch my-2"
                                        uncheckedIcon={<div className="react-switch-off">OFF</div>}
                                        checkedIcon={<div className="react-switch-on">ON</div>}
                                    />
                                </Col>
                                <Col md={2}>
                                    <Form.Label htmlFor="status" className="d-block">
                                        Status
                                    </Form.Label>
                                    <Switch
                                        onChange={Statushendler}
                                        name="status"
                                        checked={NewData.status === 1 ? true : false}
                                        offColor="#C8C8C8"
                                        onColor="#0093ed"
                                        height={30}
                                        width={70}
                                        className="react-switch my-2"
                                        uncheckedIcon={<div className="react-switch-off">OFF</div>}
                                        checkedIcon={<div className="react-switch-on">ON</div>}
                                    />
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Footer className="text-end">
                            <Button variant="primary" className="me-3" onClick={Submit}>
                                Save
                            </Button>
                            <Link to={params.id ? `/news-headline/view/${params.id}` : '/news-headline'}>
                                <Button variant="secondary">Cancel</Button>
                            </Link>
                        </Card.Footer>
                    </Card>
                </Form>
            </div>
        </Layout>
    );
};

export default NewsHeadlineEdit;
// git init
// git add README.md
// git commit -m "first commit"
// git branch -M main
// git remote add origin https://github.com/jarvis311/dsadadaada.git
// git push -u origin main
