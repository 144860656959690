import React, { useState } from "react";
import { Row, Col, Card, Button, Form, Breadcrumb } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import Layout from '../../layout/Layout';
import Switch from 'react-switch'
import { API } from "../../App";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

const VehicleCategoryAdd = () => {
  const token = Cookies.get("fmljwt");
  const navigate = useNavigate()
  const [validated, setvalidated] = useState(false)
  const [status, setstatus] = useState(0)
  const [thum_image, setthum_image] = useState("")
  const [Data, setData] = useState([{
    name: "",
  }])
  const [isLoading, setIsLoading] = useState(false);


  const image_hendler = (e) => {
    setthum_image(e.target.files[0]);
  };

  const SaveData = async (e) => {
    setData({ ...Data, [e.target.name]: e.target.value })
  }

  const Submite = async () => {
    if (Data.name == undefined || thum_image.length == 0) {
      setvalidated(true)
    } else {
      setIsLoading(true);
      const Form = new FormData()
      Form.append("category_name", Data.name)
      Form.append("thumb_image", thum_image)
      Form.append("status", status)
      const Result = await API.post("/add_categorie", Form, { headers: { authorization: `${token}` } })
      setIsLoading(false)
      toast.success("Data Saved successfully");
      navigate("/vehicle-category")
    }
  }
  return (
    <Layout sidebar={true}>
      {isLoading && <div className="loader" ></div>}
      <div className="page-heading">
        <h3>Vehicle Category Add</h3>
        <Breadcrumb className="d-none d-sm-none d-md-none d-lg-block">
          <Breadcrumb.Item >
            <Link to="/"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item >
            <Link to="/vehicle-category">Vehicle Category</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Vehicle Category Add</Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <div className="page-content">
        <Form noValidate validated={validated}>
          <Card className="mb-4">
            <Card.Body>
              <Row>
                <Col md={6}>
                  <Form.Label htmlFor="categoryname">Category Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    className="my-2"
                    // value={Data.title}
                    onChange={SaveData}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Category Name Field Is Require
                  </Form.Control.Feedback>
                </Col>
                <Col md={6}>
                  <Form.Label htmlFor="thumbimage">Thumb Image</Form.Label>
                  <Form.Control
                    type="file"
                    name="image"
                    className="my-2"
                    // value={Data.title}
                    onChange={image_hendler}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Thumb Image Field Is Require
                  </Form.Control.Feedback>
                </Col>
                <Col md={2}>
                  <Form.Label htmlFor="status" className="d-block">
                    Status
                  </Form.Label>
                  <Switch
                  //  onChange={(checked) => { if (checked === true) { setstatus(1)} else {setstatus(0)} }}
                    onChange={(checked) => checked === true ? setstatus(1) : setstatus(0)}
                    name="status"
                    checked={status === 1 ? true : false}
                    offColor="#C8C8C8"
                    onColor="#0093ed"
                    height={30}
                    width={70}
                    className="react-switch my-2"
                    uncheckedIcon={
                      <div className="react-switch-off">OFF</div>
                    }
                    checkedIcon={<div className="react-switch-on">ON</div>}
                  />
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer className="text-end">
              <Button variant="primary" onClick={Submite} className="me-3">Save</Button>
              <Link to='/vehicle-category'>
                <Button variant="secondary">Cancel</Button>
              </Link>
            </Card.Footer>
          </Card>
        </Form>
      </div>
    </Layout>
  )
}

export default VehicleCategoryAdd