import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Row } from "react-bootstrap";
import Layout from '../../layout/Layout';
import { SelectPicker } from 'rsuite';
import Fancybox from "../../Component/FancyBox";
import { API } from '../../App';
import $ from "jquery";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import { toast } from 'react-toastify';
import { AuthContext } from '../../AppContext';


var DropDownArr = []
var reloadId = [];
const NewsHeadlineView = () => {
  const { userPermission, token } = useContext(AuthContext)
  const params = useParams();
  const [CategoryData, setCategoryData] = useState([])
  const [DropDropValue, setDropDropValue] = useState([])
  const [id, setid] = useState({ id: params.id });
  const [Data, SetData] = useState({
    id: "",
    category_id: "",
    vehicale_category_id: "",
    brand_id: "",
    tag_id: "",
    title: "",
    description: "",
    news_url: "",
    headtag: "",
    date: "",
    image: "",
    websiteimage: "",
    status: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate()
  const Dropdown_Name = async () => {
    const Result = await API.post("/get-all-news-headline", {}, { headers: { authorization: `${token}` } })
    DropDownArr = []
    reloadId = []
    // DropDownArr.push({label:"Select Title" , value:"" })
    Result.data.data.map((val, i) => {
      DropDownArr.push({ label: val.title, value: val.id })
      reloadId.push(val.id)
    })
    setDropDropValue(DropDownArr)
  }
  console.log('Data >>>', Data)
  const Getview = async (Eid) => {
    setIsLoading(true)
    try {
      const result = await API.post(`/get-news-headline/${Eid || params.id}`, {}, { headers: { authorization: `${token}` } });
      SetData({
        id: result.data.data.id,
        category_id: result?.data?.data?.newsHeadlineCategory || "NA",
        vehicale_category_id: result?.data?.data?.newsVehicleCategory?.name || "NA",
        brand_id: result?.data?.data?.newsVehicleBrands?.name,
        tag_id: result?.data?.data?.tag_id?.name || "NA",
        title: result.data.data.title,
        description: result.data.data.description,
        news_url: result.data.data.news_url,
        headtag: result.data.data.headtag,
        date: result.data.data.date,
        image: result.data.data.image,
        websiteimage: result.data.data.websiteimage,
        status: result.data.data.status,
      });
      // setTotalPageCount()
      setid({ id: result.data.data.id })
      var devname = []
      result?.data?.data.newsHeadlineCategory?.map((val) => {
        devname.push(val.name)
      })
      setCategoryData(devname)
      setIsLoading(false)

    } catch (error) {
      setIsLoading(false)

    } finally {
      setIsLoading(false)

    }

  };

  const selectpickerData = (e) => {
    setid({ id: e });
    Getview(e);
    navigate(`/news-headline/view/${e}`)
  };

  useEffect(() => {
    Dropdown_Name()
    Getview()
  }, [])

  let count = 10
  let swalCountdownInterval
  const Deleteproject = async (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-danger btn-lg counter",
        cancelButton: "btn btn-primary btn-lg me-3",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure you want to delete?",
        text: "You won't be able to revert this!",
        imageUrl: '../../icon/alert.svg',
        imageWidth: 80,
        imageHeight: 80,
        confirmButtonText: "OK (10)",
        cancelButtonText: "Cancel",
        showCancelButton: true,
        reverseButtons: true,
        didOpen: () => {
          $(".swal2-confirm").attr('disabled', true);
          swalCountdownInterval = setInterval(function () {
            count--
            if (count < 1) {
              $(".counter").text(`OK`)
              $(".swal2-confirm").attr('disabled', false);
              clearInterval(swalCountdownInterval)
            } else {
              $(".counter").text(`OK (${count})`)
            }
          }, 1000);
        }
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          const ind = reloadId.indexOf(Data.id)
          reloadId.splice(ind, 1)
          const result = await API.post(`/delete-news-headline/${id || Data.id}`, {}, { headers: { authorization: `${token}` } });
          if (result.data.status) {
            toast.success(result.data.response_message)
            navigate('/news-headline')
          }
          // if (reloadId.length === 0) {
          //   // window.location.replace(`http://localhost:3000/news_Headline`)
          //   window.location.replace(`${process.env.REACT_APP_BASE_URL}news_Headline`)
          // } else {
          //   // window.location.replace(`http://localhost:3000/view/news_Headline/${reloddId}`)
          //   window.location.replace(`${process.env.REACT_APP_BASE_URL}view/news_Headline/${reloadId}`)
          // }
        } else {
          count = 10
          clearInterval(swalCountdownInterval)
        }
      });
  };

  return (
    <Layout sidebar={true}>
      {/* {isLoading && <div className="loader" ></div>} */}

      <div className="page-heading">
        <h3><Link to="/news-headline" className='btn btn-primary btn-icon-lg me-3'><i className='bx bxs-left-arrow-alt'></i></Link>News Headline View</h3>
        <div className="page-heading-right">
          <SelectPicker data={DropDropValue} defaultValue={id.id} value={Number(id.id)} cleanable={false} className="wv-200 my-1 ms-3" onChange={(e) => selectpickerData(e)} placeholder="Select Title" placement="bottomEnd" />
          {userPermission["News-Headlines"]?.edit == 1 && <Link to={`/news-headline/edit/${id.id}`}>
            <Button variant="primary ms-3 my-1" value="edit">{params.id ? "Edit" : "Add"}</Button>
          </Link>}
          {userPermission["News-Headlines"]?.delete == 1 && <Button variant="danger ms-3 my-1 btn-icon-lg" type="button" onClick={(i) => Deleteproject()}><i className="bx bx-trash-alt"></i></Button>
          }
        </div>
      </div>
      <div className='page-content'>
        <Card>
          <Card.Body className=''>
            <Row>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Category</p><span>{Data.category_id.length !== 0 ? Data.category_id.map(item => item.name.charAt(0).toUpperCase() + item.name.slice(1)).join(', ') : "-"}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Title</p><span>{Data.title}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Description</p>
                  <span>{Data.description}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Date</p>
                  <span>{Data.date}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-4">
                  <p className="mb-1 fw-bold">Image</p>
                  <Fancybox>
                    <a data-fancybox="gallery" href={Data.image}>
                      <img
                        src={Data.image}
                        className="hv-40 rounded-3"
                        alt="Image"
                      />
                    </a>
                  </Fancybox>
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-4">
                  <p className="mb-1 fw-bold">Website Image</p>
                  <Fancybox>
                    <a data-fancybox="gallery" href={Data.websiteimage}>
                      <img
                        src={Data.websiteimage}
                        className="hv-40 rounded-3"
                        alt="Image"
                      />
                    </a>
                  </Fancybox>
                </div>
              </Col>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Status</p><span>{Data.status == 1 ? "On" : "Off"}</span>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    </Layout>
  )
}

export default NewsHeadlineView