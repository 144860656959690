import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button, Form, InputGroup, Image, Breadcrumb } from 'react-bootstrap';
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from '../../layout/Layout';
import Switch from 'react-switch'
import { API } from "../../App";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

const NewsCategoryEdit = () => {
  const token = Cookies.get("fmljwt");
  const navigate = useNavigate()
  const [validated, setvalidated] = useState(false)
  const [Data, setData] = useState([])
  const { id } = useParams()
  const [AddData, setAddData] = useState({
    name: "",
    status: 0,
    Image: []
  })
  const [isLoading, setIsLoading] = useState(false);

  const SetDefaultGetData = async () => {
    setIsLoading(true)
    try {
      const Result = await API.post(`/get-news-category_by_id/${id}`, {}, { headers: { authorization: `${token}` } })
      setAddData({
        name: Result.data.data.name,
        status: Result.data.data.status,
        Image: Result.data.data.image
      })
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)

    } finally {
      setIsLoading(false)
    }

  }
  // const addReturn = () => {
  //   setAddData({

  //   })

  // }

  // const removeReturn = (id, i) => {
  //   if (id == undefined) {
  //     const data = [...AddData]
  //     data.splice(i, 1)
  //     setAddData(data)
  //   } else {
  //     const data = [...AddData]
  //     data.splice(i, 1)
  //     setAddData(data)
  //   }
  // }

  const submiteData = async () => {
    setIsLoading(true)
    const Form = new FormData()
    if (typeof AddData.Image == 'object') {
      Form.append(`image`, AddData.Image);
    }
    Form.append(`name`, AddData.name);
    Form.append(`status`, AddData.status);
    try {
      let result = await API.post(`/update-news-category/${id}`, Form, { headers: { authorization: `${token}` } });
      if (result.data.status) {
        navigate('/news-category')
        toast.success(result.data.response_message)
        setIsLoading(false)
      } else {
        toast.success(result.data.response_message)
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
    } finally {
      setIsLoading(false)

    }
  }
  useEffect(() => {
    SetDefaultGetData()
  }, [])

  return (
    <Layout sidebar={true}>
      {isLoading && <div className="loader" ></div>}
      <div className="page-heading">
        <h3>News Category Edit</h3>
        <div className="page-heading-right">
          <Breadcrumb className="d-none d-sm-none d-md-none d-lg-block">
            <Breadcrumb.Item >
                <Link to="/"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item >
                <Link to="/news-category">News Category</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>News Category Edit</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <div className="page-content">
        <Form noValidate validated={validated}>
          <Card className="mb-4">
            <Card.Body>
              <Row>
                <Col md={5}>
                  <Form.Label htmlFor="name">Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    className="my-2"
                    value={AddData.name}
                    onChange={(e) => setAddData({ ...AddData, name: e.target.value })}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Name Field Is Require
                  </Form.Control.Feedback>
                </Col>
                <Col md={5}>
                  <Form.Label htmlFor="image">Image</Form.Label>
                  <InputGroup className="my-2">
                    <Form.Control
                      type="file"
                      name="Image"
                      onChange={(e) => setAddData({ ...AddData, Image: e.target.files[0] })}
                      required
                    />
                    <InputGroup.Text>
                      <Image
                        src={AddData.Image}
                        className="hv-30 rounded-3"
                      ></Image>
                    </InputGroup.Text>
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">
                    Image Field Is Require
                  </Form.Control.Feedback>
                </Col>
                <Col md={1}>
                  <Form.Label htmlFor="status" className="d-block">
                    Status
                  </Form.Label>
                  <Switch
                    onChange={(e) => setAddData({ ...AddData, status: e == true ? 1 : 0 })}
                    name="status"
                    checked={AddData.status}
                    offColor="#C8C8C8"
                    onColor="#0093ed"
                    height={30}
                    width={70}
                    className="react-switch my-2"
                    uncheckedIcon={
                      <div className="react-switch-off">OFF</div>
                    }
                    checkedIcon={<div className="react-switch-on">ON</div>}
                  />
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer className="text-end">
              <Button
                variant="primary"
                className="me-3"
                onClick={submiteData}
              >
                Save
              </Button>
              <Link to="/news-category">
                <Button variant="secondary">Cancle</Button>
              </Link>
            </Card.Footer>
          </Card>
        </Form>
      </div>
    </Layout>
  )
}

export default NewsCategoryEdit