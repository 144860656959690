import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Form, Card, Table } from "react-bootstrap";
import Layout from "../../layout/Layout";
import Pagination from "rc-pagination";
import Switch from "react-switch";
import { API } from "../../App";
import { toast } from "react-toastify";
import $, { data } from "jquery";
import Swal from "sweetalert2";
import { SelectPicker } from "rsuite";
import Cookies from "js-cookie";
import { AuthContext } from "../../AppContext";

var cityNAmeArray = [];
var brandnameArray = [];
var Page_array = [];
var status_array = [];
var Added_array = [];
var lp = [];

const ServiceDealer = () => {
  const { userPermission, token } = useContext(AuthContext)
  const [Data, setData] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [size, setSize] = useState(perPage);
  const [current, setCurrent] = useState(1);
  const [PageHook, setPageHook] = useState([]);
  const [city_nameData, setcity_nameData] = useState([]);
  const [Brand_nameData, setBrand_nameData] = useState([]);
  const [statusHook, setstatusHook] = useState([]);
  const [Added, setAdded] = useState([]);
  const [totalPageCount, setTotalPageCount] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [query, setquery] = useState({
    searchvalue: "",
    cityid: "",
    brand: "",
    type: "",
    added_by: "",
  });
  const GetData = async () => {
    setIsLoading(true);
    try {
      const result = await API.post(
        "/get_all_service_dealer",
        {
          limit: size,
          page: current,
          searchvalue: query.searchvalue,
          cityid: query.cityid,
          brand: query.brand,
          type: query.type,
          added_by:
            (query.added_by === 0 && "admin") ||
            (query.added_by === 1 && query.added_by) ||
            (query.added_by === "All" && ""),
        },
        { headers: { authorization: `${token}` } }
      );
      setData(result.data.data);
      PageGetData();
      city_name_dropdown();
      // area_name_dropdown()
      StatusGetData();
      AddedGetData();
      setTotalPageCount(result.data.totalcount);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const PerPageChange = (value) => {
    setSize(value);
    const newPerPage = Math.ceil(totalPageCount / value);
    if (current > newPerPage) {
      setCurrent(newPerPage);
    }
  };

  const paginationData = (page, pageSize) => {
    setCurrent(page);
    setSize(pageSize);
  };

  const PrevNextArrow = (current, type, originalElement) => {
    if (type === "prev") {
      return <button className="paggination-btn">Previous</button>;
    }
    if (type === "next") {
      return <button className="paggination-btn">Next</button>;
    }
    return originalElement;
  };

  const PageGetData = async () => {
    var PageNumber = [10, 25, 50, 100];
    Page_array = [];
    PageNumber.map((val, index) => {
      Page_array.push({ label: val, value: val });
    });
    setPageHook(Page_array);
  };

  const StatusGetData = async () => {
    var status = ["Active", "Deactive"];
    var status = [
      { label: "Car", value: 2 },
      { label: "Bike", value: 1 },
    ];
    status_array = [];
    status_array.push({ label: "Select Type", value: "" });
    status.map((val, index) => {
      status_array.push({ label: val.label, value: val.value });
    });
    setstatusHook(status_array);
  };

  const AddedGetData = async () => {
    var Added_By = [
      { label: "Admin", value: 1 },
      { label: "User", value: 0 },
    ];
    Added_array = [];
    Added_array.push({ label: " Added By", value: "All" });
    Added_By.map((val, index) => {
      Added_array.push({ label: val.label, value: val.value });
    });
    setAdded(Added_array);
  };

  const city_name_dropdown = async () => {
    const Result = await API.post(
      "/getAllcity",
      {},
      { headers: { authorization: `${token}` } }
    );
    cityNAmeArray = [];
    cityNAmeArray.push({ label: "Select City", value: "", role: "" });
    Result.data.data.map((val) => {
      cityNAmeArray.push({ label: val.name, value: val.id });
    });
    setcity_nameData(cityNAmeArray);
  };

  const brand_name_dropdown = async (e) => {
    const Form = new FormData();
    Form.append("typeId", e);
    const Result = await API.post("/get_service_center_brand_option", Form, {
      headers: { authorization: `${token}` },
    });
    brandnameArray = [];
    brandnameArray.push({ label: "Select Brand Name", value: "", role: "" });
    Result.data.data.map((val) => {
      brandnameArray.push({ label: val.brand_name, value: val.id });
    });
    setBrand_nameData(brandnameArray);
  };

  let count = 10;
  let swalCountdownInterval;
  const DeleteData = async (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-danger btn-lg counter",
        cancelButton: "btn btn-primary btn-lg me-3",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure you want to delete?",
        text: "You won't be able to revert this!",
        imageUrl: "../../icon/alert.svg",
        imageWidth: 80,
        imageHeight: 80,
        confirmButtonText: "OK (10)",
        cancelButtonText: "Cancel",
        showCancelButton: true,
        reverseButtons: true,
        didOpen: () => {
          $(".swal2-confirm").attr("disabled", true);
          swalCountdownInterval = setInterval(function () {
            count--;
            if (count < 1) {
              $(".counter").text(`OK`);
              $(".swal2-confirm").attr("disabled", false);
              clearInterval(swalCountdownInterval);
            } else {
              $(".counter").text(`OK (${count})`);
            }
          }, 1000);
        },
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          const form = new FormData();
          form.append("id", id);
          await API.post(`/delete_service_dealer/${id}`, form, {
            headers: { authorization: `${token}` },
          });
          toast.success("Deleted successfully");
          setCurrent(1);
          GetData();
        } else {
          count = 10;
          clearInterval(swalCountdownInterval);
        }
      });
  };

  // useEffect(() => {
  //   brand_name_dropdown(query.type);
  // }, [query.type]);
  // console.log("query >>>>>", query);

  // const Seaching = async (e, name) => {
  //   if (name == "type") {
  //     brand_name_dropdown(e);
  //   }
  //   setCurrent(1);
  //   if (name === "searchvalue") {
  //     setquery({ ...query, [e.target.name]: e.target.value });
  //   } else if (name === "") {
  //     GetData();
  //   } else {
  //     setquery({ ...query, [name]: e });
  //   }
  //   const Form = new FormData();
  //   Form.append(
  //     "searchvalue",
  //     name == "searchvalue" ? e.target.value : query.searchvalue
  //   );
  //   Form.append("cityid", name == "cityid" ? e : query.cityid);
  //   Form.append("brand", name == "brand" ? e : query.brand);
  //   Form.append("type", name == "type" ? e : query.type);
  //   Form.append("added_by", name == "added_by" ? e : query.added_by);
  //   const result = await API.post("/searching_dealer", Form, {
  //     headers: { authorization: `${token}` },
  //   });
  //   setData(result.data.Data);
  // };
  const Togglechange = async (id, e, name) => {
    var status;
    var featured;

    if (name === "status") {
      status = e === true ? 1 : 0;
      const result = await API.post("/change_status", { id: id, status: status }, {
        headers: { authorization: `${token}` },
      });
      if (result) {
        toast.success("Status Update successfully");
        GetData();
      }
    }

    if (name === "featured") {
      featured = e === true ? 1 : 0;
      const result = await API.post(
        "/change_featured",
        { id: id, featured: featured },
        {
          headers: { authorization: `${token}` },
        }
      );
      if (result) {
        toast.success("Status Update successfully");
        GetData();
      }
    }
  };

  useEffect(() => {
    GetData();
  }, [
    current,
    size,
    query.searchvalue,
    query.cityid,
    query.brand,
    query.type,
    query.added_by,
  ]);
  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3 className="my-1">Service Dealer</h3>
        <div className="page-heading-right">
          <Form.Control
            type="text"
            name="searchvalue"
            id=""
            placeholder="Search Dealer Name"
            className="wv-200 my-1 ms-3"
            onChange={(e) => {
              setquery({ ...query, searchvalue: e.target.value });
              setCurrent(1);
            }}
          />
          <SelectPicker
            cleanable={false}
            data={city_nameData}
            defaultValue={""}
            className="wv-200 my-1 ms-3"
            placeholder="Select City"
            value={query.cityid}
            // value={search[2].search.status}
            // onChange={(e) => Seaching(e, "cityid")}
            onChange={(e) => {
              setquery({ ...query, cityid: e });
              setCurrent(1);
            }}
          />
          <SelectPicker
            cleanable={false}
            data={statusHook}
            searchable={false}
            defaultValue={""}
            value={query.type}
            className="wv-200 my-1 ms-3"
            placeholder="Select Type"
            // value={search[2].search.status}
            // onChange={(e) => Seaching(e, "type")}
            onChange={(e) => {
              setquery({
                type: e,
                added_by: query.added_by,
                cityid: query.cityid,
                searchvalue: query.searchvalue,
                brand: ""
              });
              setCurrent(1);
              brand_name_dropdown(e);
            }}
          />

          <SelectPicker
            cleanable={false}
            data={Brand_nameData}
            defaultValue={""}
            value={query.brand}
            className="wv-200 my-1 ms-3"
            placeholder="Select Brand Name"
            // value={search[2].search.status}
            // onChange={(e) => Seaching(e, "brand")}
            onChange={(e) => {
              setquery({ ...query, brand: e });
              setCurrent(1);
            }}
          />

          {/* <SelectPicker
          cleanable={false}
          data={area_nameData}
          searchable={false}
          defaultValue={""}
          className="wv-200 my-1 ms-3"
          placeholder="Select City"
          // value={search[2].search.status}
          onChange={(e) => Seaching(e, "state")}
        /> */}

          <SelectPicker
            cleanable={false}
            data={Added}
            searchable={false}
            defaultValue={""}
            className="wv-200 my-1 ms-3"
            placeholder="Select Satus"
            value={query.added_by}
            // value={search[2].search.status}
            // onChange={(e) => Seaching(e, "added_by")}
            onChange={(e) => {
              setquery({ ...query, added_by: e });
              setCurrent(1);
            }}
          />
          <SelectPicker
            cleanable={false}
            data={PageHook}
            searchable={false}
            // style={{ width: 224 }}
            defaultValue={10}
            className="wv-100 my-1 ms-3"
            onChange={(e) => {
              setSize(parseInt(e));
              setCurrent(1);
            }}
          />
          {userPermission["Service-Center-Dealer"]?.add == 1 && <Link to="/service-dealer/add" className="my-1 ms-3">
            <Button variant="primary" value="create">
              Add New
            </Button>
          </Link>}
        </div>
      </div>
      <div className="page-content">
        <Card>
          <Card.Body className="position-relative">
            {isLoading && <div className="loader table-loader"></div>}
            <Table bordered responsive>
              <thead>
                <tr>
                  <th width="3%" className="text-center">
                    No
                  </th>
                  <th width="10%">Brand Name</th>
                  <th width="10%">City Name</th>
                  <th width="10%">Dealer Name</th>
                  <th width="15%">Address</th>
                  <th width="10%">Number</th>
                  <th width="15%">Email</th>
                  <th width="6%">Added By</th>
                  <th width="7%" className="text-center">
                    Featured
                  </th>
                  <th width="7%" className="text-center">
                    Status
                  </th>
                  {/* <th width="10%" >Dealer Live</th> */}
                  <th width="7%" className="text-center">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {Data.map((val, i) => {
                  lp = [];
                  val?.BrandDetails?.map((v) => {
                    lp.push(v.brand_name);
                  });
                  return (
                    <tr>
                      <td className="text-center">
                        {current === 1 ? i + 1 : current * size + i + 1 - size}
                      </td>
                      <td>{lp.toString()}</td>
                      <td>{val.cityDetail.name}</td>
                      <td>{val.name}</td>
                      <td>{val.address}</td>
                      <td>{val.number}</td>
                      <td>{val.email || "-"}</td>
                      <td>{val?.added_by == 1 && "Admin" || val?.added_by == 0 && "User"}</td>
                      <td className="text-center">
                        <Switch
                          onChange={(e) => {
                            Togglechange(val.id, e, "featured");
                          }}
                          checked={val.featured === 1 ? true : false}
                          offColor="#C8C8C8"
                          onColor="#0093ed"
                          height={30}
                          width={70}
                          disabled={userPermission["Service-Center-Dealer"]?.edit != 1}
                          className="react-switch"
                          uncheckedIcon={
                            <div className="react-switch-off">OFF</div>
                          }
                          checkedIcon={
                            <div className="react-switch-on">ON</div>
                          }
                        />
                      </td>
                      <td className="text-center">
                        <Switch
                          onChange={(e) => {
                            Togglechange(val.id, e, "status");
                          }}
                          checked={val.status === 1 ? true : false}
                          offColor="#C8C8C8"
                          onColor="#0093ed"
                          disabled={userPermission["Service-Center-Dealer"]?.edit != 1}
                          height={30}
                          width={70}
                          className="react-switch"
                          uncheckedIcon={
                            <div className="react-switch-off">OFF</div>
                          }
                          checkedIcon={
                            <div className="react-switch-on">ON</div>
                          }
                        />
                      </td>
                      {/* <td>1</td> */}
                      <td className="text-center">
                        <Link to={`/service-dealer/view/${val.id}`}>
                          <Button
                            variant="outline-warning"
                            size="sm"
                            className="me-2 btn-icon"
                          >
                            <i className="bx bx-show"></i>
                          </Button>
                        </Link>
                        {userPermission["Service-Center-Dealer"]?.delete == 1 && <Button
                          variant="outline-danger"
                          onClick={() => DeleteData(val.id)}
                          size="sm"
                          className="btn-icon"
                        >
                          <i className="bx bx-trash-alt"></i>
                        </Button>}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              {isLoading == false && totalPageCount === 0 ? (
                <tr>
                  <td colSpan="100%" className="p-0">
                    <div className="no-found">
                      <img src="../../not-found/image.svg" />
                      <p>No Found Service Dealer</p>
                    </div>
                  </td>
                </tr>
              ) : (
                ""
              )}
            </Table>
            {totalPageCount > size ? (
              <div className="pagination-custom">
                <Pagination
                  showTitle={false}
                  className="pagination-data"
                  onChange={paginationData}
                  total={totalPageCount}
                  current={current}
                  pageSize={size}
                  showSizeChanger={false}
                  itemRender={PrevNextArrow}
                  onShowSizeChange={PerPageChange}
                />
              </div>
            ) : (
              ""
            )}
          </Card.Body>
        </Card>
      </div>
    </Layout>
  );
};

export default ServiceDealer;
