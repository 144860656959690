import React, { useState } from "react";
import { Breadcrumb, Button, Card, Col, Form, Row } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { API } from "../../App";
import Layout from '../../layout/Layout';
import Cookies from "js-cookie";

const ModuleAdd = () => {
  const token = Cookies.get("fmljwt");
  const [validated, setvalidated] = useState(false)
  const navigate = useNavigate()
  const [Data, setData] = useState([{
    name: "",
    path: "",
  }])

  const SaveData = async (e) => {
    setData({ ...Data, [e.target.name]: e.target.value })
  }

  const Submite = async () => {
    if (Data.name == undefined || Data.path == undefined) {
      setvalidated(true)
    } else {
      const Form = new FormData()
      Form.append('display_name', Data.name)
      Form.append('name', Data.path)
      const Result = await API.post(`/permission/create`, Form, { headers: { authorization: `${token}` } })
      if (Result) {
        toast.success("Data Saved successfully");
        navigate(`/module`)
      }
    }
  }
  return (
    <>
      <Layout sidebar={true}>
        <div className="page-heading">
          <h3>Module Add</h3>
          <Breadcrumb className="d-none d-sm-none d-md-none d-lg-block">
            <Breadcrumb.Item>
              <Link to="/home">
                <i className="bx bx-home-alt me-2 fs-5"></i> Home
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/module">Module List</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Module Add</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div className="page-content">
          <Form noValidate validated={validated}>
              <Card className="mb-4">
                <Card.Body>
                  <Row>
                    <Col md={4}>
                      <Form.Label htmlFor="name">Module Name</Form.Label>
                      <Form.Control type="text" className="my-2" name="name" onChange={SaveData} required />
                      <Form.Control.Feedback type="invalid">
                        Module Name Field Is Require
                      </Form.Control.Feedback>
                    </Col>
                    <Col md={4}>
                      <Form.Label htmlFor="name">Route</Form.Label>
                      <Form.Control type="text" className="my-2" name="path" onChange={SaveData} required />
                      <Form.Control.Feedback type="invalid">
                        Route Field Is Require
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer className="text-end">
                  <Button variant="primary" className="me-3" onClick={Submite} >
                    Save
                  </Button>
                  <Link to="/module">
                    <Button variant="secondary">Cancel</Button>
                  </Link>
                </Card.Footer>
              </Card>
          </Form>
        </div>
      </Layout>
    </>
  )
}

export default ModuleAdd