import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Card, Col, Row } from "react-bootstrap";
import Layout from "../../layout/Layout";
import { SelectPicker } from "rsuite";
import { API } from "../../App";
import $ from "jquery";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { AuthContext } from "../../AppContext";

var DropDownArr = [];
var reloadId = [];
const DrivingSchoolCityView = () => {
  const { userPermission, token } = useContext(AuthContext)
  const navigate = useNavigate();
  const params = useParams();
  // const [variableData, setvariableData] = useState([]);
  const [id, setid] = useState({ id: params.id });
  const [Data, SetData] = useState({
    id: "",
    state_id: "",
    city_name: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const [DropDropValue, setDropDropValue] = useState([]);

  const Dropdown_Name = async () => {
    const Result = await API.post(
      "/get_all_driving_school_city",
      {},
      { headers: { authorization: `${token}` } }
    );
    DropDownArr = [];
    reloadId = [];
    Result?.data?.data.map((val, i) => {
      DropDownArr.push({ label: val?.city_name, value: val?.id });
      reloadId.push(val.id);
    });
    setDropDropValue(DropDownArr);
  };

  const Getview = async (Eid) => {
    setIsLoading(true);
    try {
      const result = await API.post(
        `/get_update_driving_school_city/${Eid !== "" ? Eid : id.id}`,
        {},
        { headers: { authorization: `${token}` } }
      );
      SetData({
        id: result.data.data.id,
        state_id: result?.data?.data?.stateDetails.state_name,
        city_name: result.data.data.city_name,
      });
      setid({ id: result?.data?.data?.id });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const selectpickerData = (e) => {
    setid({ id: e });
    Getview(e);
    navigate(`/driving-school-city/view/${e}`);
  };

  useEffect(() => {
    Dropdown_Name();
    Getview("");
  }, []);

  let count = 10;
  let swalCountdownInterval;
  const Deleteproject = async (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-danger btn-lg counter",
        cancelButton: "btn btn-primary btn-lg me-3",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure you want to delete?",
        text: "You won't be able to revert this!",
        imageUrl: "../../icon/alert.svg",
        imageWidth: 80,
        imageHeight: 80,
        confirmButtonText: "OK (10)",
        cancelButtonText: "Cancel",
        showCancelButton: true,
        reverseButtons: true,
        didOpen: () => {
          $(".swal2-confirm").attr("disabled", true);
          swalCountdownInterval = setInterval(function () {
            count--;
            if (count < 1) {
              $(".counter").text(`OK`);
              $(".swal2-confirm").attr("disabled", false);
              clearInterval(swalCountdownInterval);
            } else {
              $(".counter").text(`OK (${count})`);
            }
          }, 1000);
        },
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          const ind = reloadId.indexOf(Data.id);
          reloadId.splice(ind, 1);
          const resultDelete = await API.post(
            `/delete_driving_school_city/${params.id || id.id || Data.id}`,
            {},
            { headers: { authorization: `${token}` } }
          );
          if (resultDelete.data.status) {
            navigate("/driving-school-city");
            toast.success(resultDelete.data.response_message);
          } else {
            toast.error(resultDelete.data.response_message);
          }
        } else {
          count = 10;
          clearInterval(swalCountdownInterval);
        }
      });
  };

  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3>
          <Link to="/driving-school-city" className="btn btn-primary btn-icon-lg me-3">
            <i className="bx bxs-left-arrow-alt"></i>
          </Link>
           Driving School City View
        </h3>
        <div className="page-heading-right">
          <SelectPicker
            data={DropDropValue}
            defaultValue={id.id}
            value={id.id}
            cleanable={false}
            className="wv-200 my-1 ms-3"
            onChange={(e) => selectpickerData(e)}
            placeholder="Select City"
            placement="bottomEnd"
          />
          {userPermission["Driving-School-City"]?.edit==1&&<Link to={`/driving-school-city/edit/${id.id}`}>
            <Button variant="primary ms-3 my-1" value="edit">
              Edit
            </Button>
          </Link>}
          {userPermission["Driving-School-City"]?.delete==1&&<Button
            variant="danger ms-3 my-1 btn-icon-lg"
            type="button"
            onClick={(i) => Deleteproject(i)}
          >
            <i className="bx bx-trash-alt"></i>
          </Button>}
        </div>
      </div>
      <div className="page-content">
        <Card>
          <Card.Body>
            {isLoading && <div className="loader"></div>}
            <Row>
              <Col md={4}>
                <div className="mb-4">
                  <p className="mb-0 fw-bold">State Name</p>
                  <span>{Data.state_id}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-4">
                  <p className="mb-0 fw-bold">City Name</p>
                  <span>{Data.city_name}</span>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    </Layout>
  );
};

export default DrivingSchoolCityView;
