import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Card, Button, Form, Breadcrumb } from 'react-bootstrap';
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from '../../layout/Layout';
import { SelectPicker } from "rsuite";
import { API } from "../../App";
import { AuthContext } from "../../AppContext";

const VehicleCompareAdd = () => {
  const { token } = useContext(AuthContext)
  const params = useParams()
  const navigate = useNavigate()
  const [categories, setCategories] = useState([]);
  const [modelname, setModelName] = useState([]);
  const [compareData, setCompareData] = useState([]);
  const [vehicleName, setVehicleName] = useState([]);
  const [vehicleName1, setVehicleName1] = useState([]);
  const [vehicleName2, setVehicleName2] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getCategoryData()
    getDropdownForModelName()
    getCompareData()
  }, [])

  // Dropdown

  const category = categories?.map((item) => ({ label: item.category_name, value: item?.id }))
  // modelname?.map((item) => (setVehicleName([...vehicleName,{ label: item.model_name, value: item?.id }])))


  useEffect(() => {
    if (modelname) {
      const updatedVehicleName = modelname.map((item) => ({
        label: item.model_name,
        value: item?.id
      }));

      setVehicleName((prevVehicleName) => [...prevVehicleName, ...updatedVehicleName]);
      setVehicleName1((prevVehicleName) => [...prevVehicleName, ...updatedVehicleName]);
      setVehicleName2((prevVehicleName) => [...prevVehicleName, ...updatedVehicleName]);
    }
  }, [modelname]);


  // api
  const getCategoryData = async () => {
    setIsLoading(true);
    try {
      const response = await API.get(`/get_categories`,  { headers: { authorization: `${token}` } })
      setCategories(response?.data?.data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false);
    }
  }
  const getDropdownForModelName = async () => {
    setIsLoading(true);
    try {
      const response = await API.post(`/dropdownformodelname`, {}, { headers: { authorization: `${token}` } })
      setModelName(response?.data?.data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false);
    }
  }
  const getCompareData = async () => {
    setIsLoading(true);
    try {
      const response = await API.post(`/get_single_comparedata/${params.id}`, {}, { headers: { authorization: `${token}` } })
      setCompareData(response?.data?.data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false);
    }
  }

  const handleChange = (e, name) => {
    if (name == "vehicle_id_1") {
      setVehicleName2(vehicleName.filter((item) => item.value != e))

    }
    if (name == "vehicle_id_2") {
      setVehicleName1(vehicleName.filter((item) => item.value != e))

    }
    const updateData = { ...compareData }
    updateData[name] = e === true ? 1 : e === false ? 0 : e
    setCompareData(updateData)
  }

  const handleSave = async () => {
    // e.preventDefault()
    setIsLoading(true);
    console.log("first")
    try {
      const formData = new FormData()
      formData.append("category_id", compareData?.category_id)
      formData.append("vehicle_id_1", compareData?.vehicle_id_1)
      formData.append("vehicle_id_2", compareData?.vehicle_id_2)
      formData.append("status", compareData?.status || 0)
      if (params.id) {

        const response = await API.post(`/update_comparedata/${params.id}`, formData, { headers: { authorization: `${token}` } }).then(() => navigate(`/vehicle-compare/view/${params.id}`))
      } else {
        const response = await API.post(`/storeComparedata`, formData, { headers: { authorization: `${token}` } }).then(() => navigate(`/vehicle-compare`))

      }

    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false);
    }
  }
  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3>Vehicle Compare {params.id ? "Edit" : "Add"}</h3>
        <Breadcrumb className="d-none d-sm-none d-md-none d-lg-block">
          <Breadcrumb.Item >
            <Link to="/"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item >
            <Link to="/vehicle-compare">Vehicle Compare</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Vehicle Compare {params.id ? "Edit" : "Add"}</Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <div className="page-content">
        <Form noValidate >
          <Card className="mb-4">
            <Card.Body>
              <Row>
                <Col md={12}>
                  <Form.Label>Category</Form.Label>
                  <SelectPicker
                    cleanable={false}
                    data={category}
                    onChange={(e) => handleChange(e, "category_id")}
                    value={compareData?.category_id}
                    className="my-2"
                    block
                    placeholder="Select Category"
                  />
                </Col>
                <Col md={5}>
                  <Form.Label>Vehicle 1</Form.Label>
                  <SelectPicker
                    cleanable={false}
                    data={vehicleName1}
                    onChange={(e) => handleChange(e, "vehicle_id_1")}
                    value={Number(compareData?.vehicle_id_1)}
                    className="my-2"
                    block
                    placeholder="Select Vehicle 1"
                  />
                </Col>
                <Col md={5}>
                  <Form.Label>Vehicle 2</Form.Label>
                  <SelectPicker
                    cleanable={false}
                    data={vehicleName2}
                    value={Number(compareData?.vehicle_id_2)}
                    onChange={(e) => handleChange(e, "vehicle_id_2")}
                    className="my-2"
                    block
                    placeholder="Select Vehicle 2"
                  />
                </Col>
                <Col md={2}>
                  <Form.Label>Status</Form.Label>
                  <Form.Check type="switch" onChange={(e) => handleChange(e.target.checked, "status")} checked={compareData?.status} className="my-2" />
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer className="text-end">
              <Button variant="primary" onClick={handleSave} className="me-3">Save</Button>
              <Link to='/vehicle-compare'>
                <Button type="submit" variant="secondary">Cancel</Button>
              </Link>
            </Card.Footer>
          </Card>
        </Form>
      </div>
    </Layout>
  )
}

export default VehicleCompareAdd