import $ from "jquery";
import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { FixedSizeList } from 'react-window';
import Swal from "sweetalert2";
import { API } from "../../App";
import { AuthContext } from "../../AppContext";
import Layout from "../../layout/Layout";


var DropDownArr = [];
var reloadId = [];
const ServiceDealerView = () => {
  const { userPermission, token } = useContext(AuthContext)
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const params = useParams();
  const [BRAND, setBRAND] = useState([]);
  const [id, setid] = useState({ id: params.id });
  const [Data, SetData] = useState({
    id: "",
    type: "",
    city_id: "",
    brand_id: "",
    name: "",
    address: "",
    zipcode: "",
    website: "",
    number: "",
    email: "",
    featured: "",
    type: "",
    paymentMode: "",
    sun: "",
    mon: "",
    tue: "",
    wed: "",
    thu: "",
    fri: "",
    sat: "",
  });
  const [options, setOptions] = useState([]);
  const Dropdown_Name = async () => {
    const Result = await API.post(
      "/getalldealer",
      {},
      { headers: { authorization: `${token}` } }
    );
    DropDownArr = [];
    reloadId = [];
    Result.data.data.map((val, i) => {
      DropDownArr.push({ label: val?.name, value: val.id });
      reloadId.push(val.id);
    });
    setOptions(DropDownArr);
  };

  const Getview = async (Eid) => {
    setIsLoading(true)
    const result = await API.post(
      `/get_service_dealer/${Eid !== "" ? Eid : id.id}`,
      {},
      { headers: { authorization: `${token}` } }
    );
    SetData({
      id: result.data.data.id,
      city_id: result.data.data.cityDetailsDealer.name,
      brand_id: result.data.data.brand_id.brand_name,
      name: result.data.data.name,
      address: result.data.data.address,
      zipcode: result.data.data.zipcode,
      website: result.data.data.website,
      number: result.data.data.number,
      email: result.data.data.email,
      featured: result.data.data.featured,
      paymentMode: result.data.data.paymentMode,
      sun: result.data.data.sun,
      mon: result.data.data.mon,
      tue: result.data.data.tue,
      wed: result.data.data.wed,
      thu: result.data.data.thu,
      fri: result.data.data.fri,
      sat: result.data.data.sat,
    });
    var brand_Data = [];
    result?.data?.data?.BrandDetails?.map((val) => {
      brand_Data.push(val.brand_name);
    });
    setid({ id: result.data.data.id });
    setBRAND(brand_Data);
    setIsLoading(false)

  };

  const selectpickerData = (e) => {
    setid({ id: e.value });
    Getview(e.value);
    navigate(`/service-dealer/view/${e.value}`)
  };

  useEffect(() => {
    Dropdown_Name();
    Getview("");
  }, []);

  let count = 10;
  let swalCountdownInterval;
  const Deleteproject = async (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-danger btn-lg counter",
        cancelButton: "btn btn-primary btn-lg me-3",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure you want to delete?",
        text: "You won't be able to revert this!",
        imageUrl: "../../icon/alert.svg",
        imageWidth: 80,
        imageHeight: 80,
        confirmButtonText: "OK (10)",
        cancelButtonText: "Cancel",
        showCancelButton: true,
        reverseButtons: true,
        didOpen: () => {
          $(".swal2-confirm").attr("disabled", true);
          swalCountdownInterval = setInterval(function () {
            count--;
            if (count < 1) {
              $(".counter").text(`OK`);
              $(".swal2-confirm").attr("disabled", false);
              clearInterval(swalCountdownInterval);
            } else {
              $(".counter").text(`OK (${count})`);
            }
          }, 1000);
        },
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          const ind = reloadId.indexOf(Data.id);
          reloadId.splice(ind, 1);
          const resultDelete = await API.post(
            `/delete_service_dealer/${params.id || id}`,
            {},
            { headers: { authorization: `${token}` } }
          );
          if (resultDelete.data.status) {
            navigate('/service-dealer')
            toast.success(resultDelete.data.response_message)
          } else {
            toast.success(resultDelete.data.response_message)
          }
          // if (reloadId.length === 0) {
          //   // window.location.replace(`http://localhost:3000/service_Dealer`)
          //   // window.location.replace(`${process.env.REACT_APP_BASE_URL}service_Dealer`)
          //   navigate("/service_Dealer");
          // } else {
          //   // window.location.replace(`http://localhost:3000/view/service_Dealer/${deloadId}`)
          //   navigate("/view/service_Dealer");
          //   // window.location.replace(`${process.env.REACT_APP_BASE_URL}view/service_Dealer/${reloadId}`)
          // }
        } else {
          count = 10;
          clearInterval(swalCountdownInterval);
        }
      });
  };

  const VirtualizedSelect = ({ options, ...rest }) => {
     
    return (
      <Select
        {...rest}
        className="customMulSelect wv-250"
        classNamePrefix="react-select"
        components={{ MenuList: VirtualizedMenuList }}
        options={options}
        onChange={(e) => selectpickerData(e)}
        value={options?.find((item)=>item.value==id.id)}
      />
    );
  };
  
  const VirtualizedMenuList = ({ children, maxHeight, getValue }) => {
    const selectedOption = getValue()[0];
    const selectedIndex = options.indexOf(selectedOption);
  
    return (
      <FixedSizeList height={maxHeight} itemCount={options.length} itemSize={35} width="100%">
        {({ index, style }) => (
          <div style={{ ...style}}>
            {children[index]}
          </div>
        )}
      </FixedSizeList>
    );
  };
  
  return (
    <Layout sidebar={true}>
                    {isLoading && <div className="loader table-loader"></div>}
      <div className="page-heading">
        <h3>
          <Link
            to="/service-dealer"
            className="btn btn-primary btn-icon-lg me-3"
          >
            <i className="bx bxs-left-arrow-alt"></i>
          </Link>
          Service Dealer View
        </h3>
        <div className="page-heading-right">
            <VirtualizedSelect options={options} className="wv-200" />
          {userPermission["Service-Center-Dealer"]?.edit == 1 && <Link to={`/service-dealer/edit/${id.id}`}>
            <Button variant="primary ms-3 my-1" value="edit">
              Edit
            </Button>
          </Link>}
          {userPermission["Service-Center-Dealer"]?.delete == 1 && <Button
            variant="danger ms-3 my-1 btn-icon-lg"
            type="button"
            onClick={(i) => Deleteproject()}
          >
            <i className="bx bx-trash-alt"></i>
          </Button>}
        </div>
      </div>
      <div className="page-content">
        <Card>
          <Card.Body>
            <Row>
              <Col md={4}>
                <div className="mb-4">
                  <p className="mb-0 fw-bold">Category</p>
                  <span>{BRAND.length !== 0 ? BRAND.toString() : "-"}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-4">
                  <p className="mb-0 fw-bold">City Name</p>
                  <span>{Data.city_id}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-4">
                  <p className="mb-0 fw-bold">Dealer Name</p>
                  <span>{Data.name}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-4">
                  <p className="mb-0 fw-bold">Address</p>
                  <span>{Data.address}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-4">
                  <p className="mb-0 fw-bold">Dealer Contact</p>
                  <span>{Data.number}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-4">
                  <p className="mb-1 fw-bold">Dealer Website</p>
                  <a href={Data.website} target="_blank">
                    <Button
                      variant="outline-info"
                      size="sm"
                      className="btn-icon"
                    >
                      <i className="bx bx-link-external"></i>
                    </Button>
                  </a>
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-4">
                  <p className="mb-0 fw-bold">Dealer Email</p>
                  <span>{Data.email}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-4">
                  <p className="mb-0 fw-bold">Featured</p>
                  <span>{Data.featured == 1 ? "On" : "Off"}</span>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    </Layout>
  );
};

export default ServiceDealerView;
