import React, { useContext, useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Card, Col, Row } from 'react-bootstrap';
import Fancybox from '../../Component/FancyBox';
import { Link } from 'react-router-dom';
import { updatePositionAffilation } from '../../service/apis';
import { toast } from 'react-toastify';
import { AuthContext } from '../../AppContext';
import { DotLottiePlayer } from '@dotlottie/react-player';
import '@dotlottie/react-player/dist/index.css';

const AffiliationViewDrag = ({ programViewData, placeName, parentCallback, affilationDataList, affilateId }) => {
    console.log(affilationDataList)
    const { userPermission } = useContext(AuthContext)
    const [updatedData, setUpdatedData] = useState([])
    const [tasks, setTasks] = useState(programViewData)
    useEffect(() => {
        programViewData && setTasks(programViewData)
    }, [programViewData])

    const handleOnDragEnd = async (result) => {
        if (!result.destination) return;

        const items = Array.from(tasks);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setTasks(items);
        let id = []
        let GroupId = ""
        let service
        items?.map((item) => (
            id.push({ id: item.id })
            // ,
            // GroupId=item.group_id
        ))
        if (placeName === "home_slider") {
            GroupId = items[0].group_id
            if (affilationDataList && affilationDataList.length) {
                let affilatedIds = []
                for (let i = 0; i < affilationDataList.length; i++) {
                    const affilationData = affilationDataList[i];
                    if (affilationData.group_id === GroupId) {
                        items.map((s) => (affilatedIds.push({ id: s.id })))
                    } else {
                        if (affilationData.affilation_data_list && affilationData.affilation_data_list.length) {
                            affilationData.affilation_data_list.map((s) => (affilatedIds.push({ id: s.id })))
                        }
                    }
                }
                await updatePositionAffilation(affilatedIds, GroupId, service).then(() => {
                    parentCallback()
                    toast.success("Position Changes Successfully")
                }
                )
            }

        } else if (placeName === "service") {
            service = true
            if (affilationDataList && affilationDataList.length) {
                let affilatedIds = []
                for (let i = 0; i < affilationDataList.length; i++) {
                    const affilationData = affilationDataList[i];
                    if (affilationData.id === affilateId) {
                        items.map((s) => (affilatedIds.push({ id: s.id })))
                    } else {
                        if (affilationData.affilation_data_list && affilationData.affilation_data_list.length) {
                            affilationData.affilation_data_list.map((s) => (affilatedIds.push({ id: s.id })))
                        }
                    }
                }
                await updatePositionAffilation(affilatedIds, GroupId, service).then(() => {
                    parentCallback()
                    toast.success("Position Changes Successfully")
                }
                )
            }
        } else {
            await updatePositionAffilation(id, GroupId).then(() => {
                parentCallback()
                toast.success("Position Changes Successfully")
            }
            )
        }
        const newPosition = result.destination.index;
    };
    return (
        <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="tasks">
                {(provided) => (
                    <Row className='g-4' {...provided.droppableProps} ref={provided.innerRef}>
                        {tasks?.map((text, index) => {
                            return (<Draggable key={index} isDragDisabled={userPermission["Affilation-Program"]?.edit != 1} draggableId={index.toString()} index={index}>
                                {(provided) => (
                                    <Col sm={12} ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}>
                                        <Card className={`border ${text?.is_default == 1 && "bg-light"}`}>
                                            <Card.Body>
                                                <Row className="g-4">
                                                    <Col lg={12}>
                                                        <Row className="g-4">
                                                            <Col md={3}>
                                                                <div>
                                                                    <p className='mb-0 fw-bold'>Provider</p><span>{text?.get_service_provider_name?.provider}</span>
                                                                </div>
                                                            </Col>
                                                            <Col md={2}>
                                                                <div>
                                                                    <p className='mb-0 fw-bold'>Service</p><span>{text?.get_affiliation_services?.services}</span>
                                                                </div>
                                                            </Col>
                                                            <Col md={2}>
                                                                <div>
                                                                    <p className='mb-0 fw-bold'>Ad Type</p><span>{text?.get_ad_type_name?.type}</span>
                                                                </div>
                                                            </Col>
                                                            <Col md={5}>
                                                                <div>
                                                                    <p className='mb-0 fw-bold'>UTM Term</p><span>{text?.utm_term}</span>
                                                                </div>
                                                            </Col>
                                                            <Col md={3}>
                                                                <div>
                                                                    <p className='mb-0 fw-bold'>Label</p><span>{text?.lable}</span>
                                                                </div>
                                                            </Col>
                                                            <Col md={2}>
                                                                <div>
                                                                    <p className='mb-0 fw-bold'>URL</p>
                                                                    <span>
                                                                        <Link to={text?.url} target="_blank" className="btn btn-info btn-sm btn-icon"><i className='bx bx-link-external'></i></Link>
                                                                    </span>
                                                                </div>
                                                            </Col>
                                                            <Col md={2}>
                                                                <div>
                                                                    <p className='mb-0 fw-bold'>Banner</p>
                                                                    <span>
                                                                        <Fancybox>
                                                                            <a href={text?.banner} data-fancybox="gallery">
                                                                                <img src={text?.banner} className="hv-30 rounded-3" alt="" />
                                                                            </a>
                                                                        </Fancybox>
                                                                    </span>
                                                                </div>
                                                            </Col>
                                                            <Col md={2}>
                                                                <div>
                                                                    <p className='mb-0 fw-bold'>Position</p><span>{text?.position}</span>
                                                                </div>
                                                            </Col>
                                                            <Col md={3}>
                                                                <div>
                                                                    <p className='mb-0 fw-bold'>Status</p><span className={text?.status ? "text-success" : 'text-danger'}>{text?.status ? "On" : "Off"}</span>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col lg={12} className='affiliationmodal'>
                                                        {/* ---- ANIM_2  and WEB_ANIM_2 ---*/}
                                                        {["ANIM_2", "WEB_ANIM_2", "ICON_2",].includes(
                                                            text?.get_ad_type_name?.type
                                                        ) && <Row className="g-3 anim_2">
                                                                <Col md={2}>
                                                                    <a href="#" className="position-relative">
                                                                        <div className="anim_2_content">
                                                                            {(text?.get_affilation_banner_image?.image1?.split(".")[text?.get_affilation_banner_image?.image1?.split(".").length - 1] == "json") ? <DotLottiePlayer src={text?.get_affilation_banner_image?.image1} autoplay loop className="lottie-player"></DotLottiePlayer>
                                                                                : <img src={text?.get_affilation_banner_image?.image1} alt="" title="" className="img-fluid" />}
                                                                            <p style={{ color: text?.get_affilation_banner_image?.headline_text_color }}>{(text?.get_affilation_banner_image && JSON.parse(text?.get_affilation_banner_image?.headline_text)) ? JSON.parse(text?.get_affilation_banner_image?.headline_text)["en"] : ""}</p>
                                                                        </div>
                                                                    </a>
                                                                </Col>
                                                            </Row>
                                                        }
                                                        {/* ---- BANNER_2  and WEB_BANNER_2 ---*/}
                                                        {["BANNER_2", "WEB_BANNER_2"].includes(
                                                            text?.get_ad_type_name?.type
                                                        ) && <Row className="g-3 banner_2">
                                                                <Col md={6}>
                                                                    <div className="banner_2_content" style={{ borderColor: text?.get_affilation_banner_image?.background_border_color, background: text?.get_affilation_banner_image?.background_color }}>
                                                                        <img src={text?.get_affilation_banner_image?.image1} alt="" title="" className="img-fluid" />
                                                                        <p style={{ color: text?.get_affilation_banner_image?.headline_text_color }}>{JSON.parse(text?.get_affilation_banner_image?.headline_text) ? JSON.parse(text?.get_affilation_banner_image?.headline_text)["en"] : ""}</p>
                                                                        <a href="" style={{ color: text?.get_affilation_banner_image?.button_text_color, borderColor: text?.get_affilation_banner_image?.button_border_color, background: text?.get_affilation_banner_image?.button_color }}>
                                                                            {JSON.parse(text?.get_affilation_banner_image?.button_text) ? JSON.parse(text?.get_affilation_banner_image?.button_text)["en"] : ""}
                                                                        </a>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        }
                                                        {/* ---- BG_BANNER_2  and WEB_BG_BANNER_2 ---*/}
                                                        {["BG_BANNER_2", "WEB_BG_BANNER_2"].includes(
                                                            text?.get_ad_type_name?.type
                                                        ) && <Row className="g-3 bg_banner_2">
                                                                <>{text?.get_affilation_banner_image["en"] != "" ?
                                                                    <Col md={5}>
                                                                        <div className="bb2 banner_bg" style={{ backgroundImage: `url(${text?.get_affilation_banner_image ? text?.get_affilation_banner_image["en"] : ""})` }}>
                                                                            <div className="bb2_content">
                                                                                <p>28 August, 2025</p>
                                                                                <span>Insurance Valid UpTo</span>
                                                                            </div>
                                                                            <div className="bb2_media">
                                                                                <img src={text?.get_affilation_banner_image?.image1} alt="" className="img-fluid" />
                                                                            </div>
                                                                            <div className="bb2_action">
                                                                                <button className="btn btn-primary btn-sm" style={{ color: text?.get_affilation_banner_image?.button_text_color, borderColor: text?.get_affilation_banner_image?.button_border_color, background: text?.get_affilation_banner_image?.button_color }}>
                                                                                    {JSON.parse(text?.get_affilation_banner_image?.button_text) ? JSON.parse(text?.get_affilation_banner_image?.button_text)["en"] : ""}
                                                                                </button>
                                                                            </div>
                                                                            <img src={text?.get_affilation_banner_image?.image2} className="bb2_servicelogo" alt="" />
                                                                        </div>
                                                                    </Col> :
                                                                    <Col md={5}>
                                                                        <div className="bb2" style={{ borderColor: text?.get_affilation_banner_image?.background_border_color, background: text?.get_affilation_banner_image?.background_color }} >
                                                                            <div className="bb2_content">
                                                                                <p>28 August, 2025</p>
                                                                                <span>Insurance Valid UpTo</span>
                                                                            </div>
                                                                            <div className="bb2_media">
                                                                                <img src={text?.get_affilation_banner_image?.image1} alt="" className="img-fluid" />
                                                                            </div>
                                                                            <div className="bb2_action">
                                                                                <button className="btn btn-primary btn-sm" style={{ color: text?.get_affilation_banner_image?.button_text_color, borderColor: text?.get_affilation_banner_image?.button_border_color, background: text?.get_affilation_banner_image?.button_color }}>
                                                                                    {JSON.parse(text?.get_affilation_banner_image?.button_text) ? JSON.parse(text?.get_affilation_banner_image?.button_text)["en"] : ""}
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </Col>}
                                                                </>
                                                            </Row>
                                                        }
                                                        {/* ---- Challan_Banner_2  and WEB_Challan_Banner_2 ---*/}
                                                        {["Challan_Banner_2", "WEB_Challan_Banner_2"].includes(
                                                            text?.get_ad_type_name?.type
                                                        ) && <Row className="g-3 challan_banner_2">
                                                                <Col md={6}>
                                                                    <div className="cb_2 cb_2_service" style={{ borderColor: text?.get_affilation_banner_image?.background_border_color, background: text?.get_affilation_banner_image?.background_color }}>
                                                                        <div className="cb_2_content">
                                                                            <h6 style={{ color: text?.get_affilation_banner_image?.headline_text_color }}>{JSON.parse(text?.get_affilation_banner_image?.headline_text) ? JSON.parse(text?.get_affilation_banner_image?.headline_text)["en"] : ""}</h6>
                                                                            <p style={{ color: text?.get_affilation_banner_image?.paragraph_color }}>{JSON.parse(text?.get_affilation_banner_image?.paragraph_text) ? JSON.parse(text?.get_affilation_banner_image?.paragraph_text)["en"] : ""}</p>
                                                                        </div>
                                                                        <div className="cb_2_media">
                                                                            <img src={text?.get_affilation_banner_image?.image1} alt="" className="img-fluid" />
                                                                        </div>
                                                                        <div className="cb_2_action">
                                                                            <button className="btn btn-primary btn-sm" style={{ color: text?.get_affilation_banner_image?.button_text_color, borderColor: text?.get_affilation_banner_image?.button_border_color, background: text?.get_affilation_banner_image?.button_color }}>
                                                                                {JSON.parse(text?.get_affilation_banner_image?.button_text) ? JSON.parse(text?.get_affilation_banner_image?.button_text)["en"] : ""}
                                                                            </button>
                                                                        </div>
                                                                        <img src={text?.get_affilation_banner_image?.image1} alt="" className="cb_2_service_icon" />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        }
                                                        {/* ---- ICON_CONTENT_2 ---*/}
                                                        {["RC_CONTENT_2"].includes(
                                                            text?.get_ad_type_name?.type
                                                        ) && <Row className="g-3 icon_content_2">
                                                                <Col md={6}>
                                                                    <div className="ic_2_card" style={{ borderColor: text?.get_affilation_banner_image?.background_border_color, background: text?.get_affilation_banner_image?.background_color }}>
                                                                        <div className="ic_2_cardbody">
                                                                            <h6 style={{ color: text?.get_affilation_banner_image?.headline_text_color }}>{JSON.parse(text?.get_affilation_banner_image?.headline_text) ? JSON.parse(text?.get_affilation_banner_image?.headline_text)["en"] : ""}</h6>
                                                                            <p style={{ color: text?.get_affilation_banner_image?.paragraph_color }}>{JSON.parse(text?.get_affilation_banner_image?.paragraph_text) ? JSON.parse(text?.get_affilation_banner_image?.paragraph_text)["en"] : ""}</p>
                                                                            <a href="" className="btn btn-primary btn-sm" style={{ color: text?.get_affilation_banner_image?.button_text_color, borderColor: text?.get_affilation_banner_image?.button_border_color, background: text?.get_affilation_banner_image?.button_color }}>
                                                                                {JSON.parse(text?.get_affilation_banner_image?.button_text) ? JSON.parse(text?.get_affilation_banner_image?.button_text)["en"] : ""}
                                                                            </a>                                                </div>
                                                                        <img src={text?.get_affilation_banner_image?.image1} alt="" className="img-fluid" />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        }
                                                        {/* ---- WEB_ICON_CONTENT_2 ---*/}
                                                        {["WEB_ICON_CONTENT_2","ICON_CONTENT_2"].includes(
                                                            text?.get_ad_type_name?.type
                                                        ) && <Row className="g-3 web_icon_content_2">
                                                                <Col md={6}>
                                                                    <div className="wic_2_card" style={{ borderColor: text?.get_affilation_banner_image?.background_border_color, background: text?.get_affilation_banner_image?.background_color }}>
                                                                        <div className="wic_2_cardheader">
                                                                            <img src={text?.get_affilation_banner_image?.image1} alt="" className="img-fluid" />
                                                                            <div className="wic_2_cardbody">
                                                                                <div>
                                                                                    <h6 style={{ color: text?.get_affilation_banner_image?.headline_text_color }}>{JSON.parse(text?.get_affilation_banner_image?.headline_text) ? JSON.parse(text?.get_affilation_banner_image?.headline_text)["en"] : ""}</h6>
                                                                                    <p>Expires on 28 July, 2022</p>
                                                                                </div>
                                                                                <a href="" className="btn btn-primary btn-sm" style={{ color: text?.get_affilation_banner_image?.button_text_color, borderColor: text?.get_affilation_banner_image?.button_border_color, background: text?.get_affilation_banner_image?.button_color }}>
                                                                                    {JSON.parse(text?.get_affilation_banner_image?.button_text) ? JSON.parse(text?.get_affilation_banner_image?.button_text)["en"] : ""}
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                        <div className="wic_2_cardfooter">
                                                                            <span style={{ color: text?.get_affilation_banner_image?.paragraph_color }}>{JSON.parse(text?.get_affilation_banner_image?.paragraph_text) ? JSON.parse(text?.get_affilation_banner_image?.paragraph_text)["en"] : ""}</span>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        }

                                                        {["WEB_OFFER_BUTTON_2", "OFFER_BUTTON_2"].includes(
                                                            text?.get_ad_type_name?.type
                                                        ) && <Row className="g-3 web_offer_button_2">
                                                                <Col md={6}>
                                                                    <div className="wob_2_card" style={{ borderColor: text?.get_affilation_banner_image?.background_border_color, background: text?.get_affilation_banner_image?.background_color }}>
                                                                        <div className="wob_2_cardbody">
                                                                            <h5 style={{ color: text?.get_affilation_banner_image?.headline_text_color }}>{JSON.parse(text?.get_affilation_banner_image?.headline_text) ? JSON.parse(text?.get_affilation_banner_image?.headline_text)["en"] : ""}</h5>
                                                                            <p style={{ color: text?.get_affilation_banner_image?.paragraph_color }}>{JSON.parse(text?.get_affilation_banner_image?.paragraph_text) ? JSON.parse(text?.get_affilation_banner_image?.paragraph_text)["en"] : ""}</p>
                                                                            <a href="" className="btn btn-primary btn-sm" style={{ color: text?.get_affilation_banner_image?.button_text_color, borderColor: text?.get_affilation_banner_image?.button_border_color, background: text?.get_affilation_banner_image?.button_color }}>
                                                                                {JSON.parse(text?.get_affilation_banner_image?.button_text) ? JSON.parse(text?.get_affilation_banner_image?.button_text)["en"] : ""}
                                                                            </a>
                                                                        </div>
                                                                        <img src={text?.get_affilation_banner_image?.image1} alt="" className="ms-3" />
                                                                        <img src={text?.get_affilation_banner_image?.image2} alt="" className="wob_2_logo" />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        }
                                                        {["SQUARE_POST_2"].includes(
                                                            text?.get_ad_type_name?.type
                                                        ) && <Row className="g-3 square_post_2">
                                                                <Col md={2}>
                                                                    <div className="sp_2_card">
                                                                        {
                                                                            (text?.get_affilation_banner_image?.image1 && typeof text?.get_affilation_banner_image?.image1 === 'string' && ['mov', 'mp4'].includes(text?.get_affilation_banner_image?.image1.split('.').pop())) ?
                                                                                <video controls>
                                                                                    <source src={text?.get_affilation_banner_image?.image1} type="video/mp4" />
                                                                                    Your browser does not support the video tag.
                                                                                </video> :
                                                                                <img src={text?.get_affilation_banner_image?.image1} alt="" className="img-fluid" />
                                                                        }
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        }

                                                        {/* ---- All Image Affiliation ---*/}
                                                        {!["ANIM_2", "WEB_ANIM_2", "BANNER_2", "WEB_BANNER_2", "BG_BANNER_2", "WEB_BG_BANNER_2", "Challan_Banner_2", "WEB_Challan_Banner_2", "ICON_CONTENT_2", "WEB_ICON_CONTENT_2", "WEB_OFFER_BUTTON_2", "SQUARE_POST_2", "OFFER_BUTTON_2", "ICON_2","RC_CONTENT_2"].includes(
                                                            text?.get_ad_type_name?.type
                                                        )
                                                            && <div className="web_icon_content_2">
                                                                <Row className="g-3">
                                                                    <Col md="auto">
                                                                        <div className="aff_img_card">
                                                                            <img src={text?.get_affilation_banner_image ? text?.get_affilation_banner_image["en"] : ""} alt="" className="img-fluid" />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </div>}
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                )}
                            </Draggable>)
                        })}
                        {provided.placeholder}
                    </Row>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default AffiliationViewDrag;
