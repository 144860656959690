import React, { useContext, useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { Button, Form, Card, Table } from "react-bootstrap";
import Layout from '../../layout/Layout';
import Pagination from "rc-pagination";
import Switch from 'react-switch';
import Fancybox from "../../Component/FancyBox";
import { API } from "../../App";
import { toast } from "react-toastify";
import $ from "jquery";
import Swal from "sweetalert2";
import { SelectPicker } from "rsuite";
import Cookies from "js-cookie";
import { AuthContext } from "../../AppContext";

var Page_array = [];
const RCDLinfo = () => {
    const { userPermission, token } = useContext(AuthContext)
    const [Data, setData] = useState([])
    const [perPage, setPerPage] = useState(10);
    const [size, setSize] = useState(perPage);
    const [current, setCurrent] = useState(1);
    const [statusHook, setstatusHook] = useState([])
    // const [TypeHook, setTypeHook] = useState([])
    const [PageHook, setPageHook] = useState([])
    const [loading, setloading] = useState(true)
    const [query, setquery] = useState({
        search: "",
        status: "",
        type: ""
    });
    const [isLoading, setIsLoading] = useState(false);
    const [totalPageCount, setTotalPageCount] = useState('')


    const Togglechange = async (id, e, name) => {
        setIsLoading(true)
        var status;
        if (name === "status") {
            status = e === true ? 1 : 0;
        }
        const Form = new FormData();
        Form.append("id", id);
        Form.append("status", status);
        const result = await API.post("/rc_dl_status", Form, { headers: { authorization: `${token}` } });
        if (result.data.status) {
            toast.success(" Status Update successfully");
            setIsLoading(false)
            setCurrent(current)
            await Seaching(query.search, query.status, query.type);
            // GetData()
        } else {
            setIsLoading(false)
        }
    };

    const PerPageChange = (value) => {
        setSize(value);
        const newPerPage = Math.ceil(totalPageCount / value);
        if (current > newPerPage) {
            setCurrent(newPerPage);
        }
    };

    const paginationData = (page, pageSize) => {
        setCurrent(page);
        setSize(pageSize);
    };

    const PrevNextArrow = (current, type, originalElement) => {
        if (type === "prev") {
            return <button className="paggination-btn">Previous</button>;
        }
        if (type === "next") {
            return <button className="paggination-btn">Next</button>;
        }
        return originalElement;
    };

    const GetData = async () => {
        setIsLoading(true)
        const Result = await API.post("/get_all_rcdl_info", { limit: size, page: current }, { headers: { authorization: `${token}` } })
        if (Result.data.status == true) {
            setData(Result.data.data)
            // StatusGetData()
            setTotalPageCount(Result.data.totalcount)
            PageGetData()
            setIsLoading(false)
        } else {
            setIsLoading(false)
            toast(Result.data.response_message)
        }

    }
    const PageGetData = async () => {
        var PageNumber = [10, 25, 50, 100]
        Page_array = []
        PageNumber.map((val, index) => {
            Page_array.push({ label: val, value: val })
        })
        setPageHook(Page_array)
    };

    let count = 10
    let swalCountdownInterval
    const DeleteData = async (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: "btn btn-danger btn-lg counter",
                cancelButton: "btn btn-primary btn-lg me-3",
            },
            buttonsStyling: false,
        });

        swalWithBootstrapButtons
            .fire({
                title: "Are you sure you want to delete?",
                text: "You won't be able to revert this!",
                imageUrl: '../../icon/alert.svg',
                imageWidth: 80,
                imageHeight: 80,
                confirmButtonText: "OK (10)",
                cancelButtonText: "Cancel",
                showCancelButton: true,
                reverseButtons: true,
                didOpen: () => {
                    $(".swal2-confirm").attr('disabled', true);
                    swalCountdownInterval = setInterval(function () {
                        count--
                        if (count < 1) {
                            $(".counter").text(`OK`)
                            $(".swal2-confirm").attr('disabled', false);
                            clearInterval(swalCountdownInterval)
                        } else {
                            $(".counter").text(`OK (${count})`)
                        }
                    }, 1000);
                }
            })
            .then(async (result) => {
                if (result.isConfirmed) {
                    await API.post(`/delete_dl_rc_info/${id}`, {}, { headers: { authorization: `${token}` } });
                    GetData();
                    setCurrent(1)
                    setquery({ search: "", status: "", type: "" })
                } else {
                    count = 10
                    clearInterval(swalCountdownInterval)
                }
            });
    };
    // const TypeGetData = async () => {
    //     var status = ["DL", "RC"]
    //     Typr_array = []
    //     Typr_array.push({ label: "All Type", value: "" })
    //     status.map((val, index) => {
    //         Typr_array.push({ label: val, value: val })
    //     })
    //     setTypeHook(Typr_array)
    // };

    const typeOptions = [
        { value: "", label: "All Type" },
        { value: "1", label: "DL" },
        { value: "2", label: "RC" },
    ]
    const statusOptions = [
        { label: "All Status", value: "fsdfsdf" },
        { value: "1", label: "On" },
        { value: "0", label: "Off" },
    ]


    const Seaching = async (search, status, type) => {
        setloading(true)
        let obj = { search: search, status: Number(status), type: type && Number(type), limit: size, page: current }
        status == "" && delete obj.status
        const result = await API.post("/rc_dl_search", obj, {
            headers: {
                authorization: `${token}`
            }
        });
        if (result.data.status) {
            setData(result.data.data);
            setTotalPageCount(result.data.totalcount)
            setIsLoading(false)
        } else {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (query?.status?.trim() !== "" || query.search.trim() !== "" || query.type.trim() !== "") {
            Seaching(query.search, query.status, query.type);
        } else {
            GetData();
        }
    }, [size, current, query.search, query.status, query.type])
    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3 className="my-1">DL / RC Info</h3>
                <div className="page-heading-right">
                    <Form.Control
                        type="text"
                        value={query.search}
                        name="search"
                        id=""
                        placeholder="Search DL-RC Title"
                        className="wv-200 my-1 ms-3"
                        // value={search[2].search.name}
                        onChange={(e) => { setquery({ ...query, search: e.target.value }); setCurrent(1) }}
                    />
                    <SelectPicker
                        cleanable={false}
                        data={typeOptions}
                        searchable={false}
                        defaultValue={""}
                        className="wv-150 my-1 ms-3"
                        value={query.type}
                        placeholder="Select Type"
                        // value={search[2].search.status}
                        onChange={(e) => { setquery({ ...query, type: e }); setCurrent(1) }}
                    />
                    <SelectPicker
                        cleanable={false}
                        data={statusOptions}
                        searchable={false}
                        value={query.status}
                        defaultValue={""}
                        className="wv-150 my-1 ms-3"
                        placeholder="Select Satus"
                        // value={search[2].search.status}
                        onChange={(e) => { setquery({ ...query, status: e }); GetData(); setCurrent(1) }}
                    />
                    <SelectPicker
                        cleanable={false}
                        data={PageHook}
                        searchable={false}
                        // style={{ width: 224 }}
                        defaultValue={10}
                        className="wv-100 my-1 ms-3"
                        onChange={(e) => {
                            setSize(Number(e));
                            setCurrent(1);
                        }}
                    />
                    {userPermission["DL-Rc-info"]?.add == 1 && <Link to="/dl-rc-info/add" className="my-1 ms-3">
                        <Button variant="primary" value="create">Add New</Button>
                    </Link>}

                </div>
            </div>
            <div className="page-content">
                <Card>
                    <Card.Body className="position-relative">
                        {isLoading && <div className="loader table-loader" ></div>}
                        <Table bordered responsive>
                            <thead>
                                <tr>
                                    <th width="4%" className="text-center">No</th>
                                    <th width="5%">Type</th>
                                    <th width="12%">Title</th>
                                    <th width="8%" className="text-center">Thumb Image</th>
                                    <th width="5%" className="text-center">En</th>
                                    <th width="5%" className="text-center">Hi</th>
                                    <th width="5%" className="text-center">Mr</th>
                                    <th width="5%" className="text-center">Gu</th>
                                    <th width="5%" className="text-center">Ta</th>
                                    <th width="5%" className="text-center">Te</th>
                                    <th width="5%" className="text-center">Kn</th>
                                    <th width="5%" className="text-center">Bn</th>
                                    <th width="5%" className="text-center">Ml</th>
                                    <th width="5%" className="text-center">Or</th>
                                    <th width="5%" className="text-center">Pa</th>
                                    <th width="8%" className="text-center">Status</th>
                                    <th width="8%" className='text-center'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    Data?.map((val, i) => {
                                        return (
                                            <tr>
                                                <td className='text-center'>{(current === 1) ? i + 1 : current * size + i + 1 - size}</td>
                                                <td>{(val?.type == 1 && "DL") || (val?.type == 2 && "RC")}</td>
                                                <td>{val?.title?.replace(/_/g, ' ')}</td>
                                                <td className="text-center">
                                                    <Fancybox>
                                                        <a href={val.thumb_image} data-fancybox="gallery">
                                                            <img src={val.thumb_image} className="hv-40 rounded-3" alt="" />
                                                        </a>
                                                    </Fancybox>
                                                </td>
                                                <td className="text-center">
                                                    <a href={val.en} target="_blank" >
                                                        <Button variant="outline-info" size="sm" className="btn-icon">
                                                            <i className="bx bx-link-external"></i>
                                                        </Button>
                                                    </a>
                                                </td>
                                                <td className="text-center">
                                                    <a href={val.hi} target="_blank" >
                                                        <Button variant="outline-info" size="sm" className="btn-icon">
                                                            <i className="bx bx-link-external"></i>
                                                        </Button>
                                                    </a>
                                                </td>
                                                <td className="text-center">
                                                    <a href={val.mr} target="_blank" >
                                                        <Button variant="outline-info" size="sm" className="btn-icon">
                                                            <i className="bx bx-link-external"></i>
                                                        </Button>
                                                    </a>
                                                </td>
                                                <td className="text-center">
                                                    <a href={val.gu} target="_blank" >
                                                        <Button variant="outline-info" size="sm" className="btn-icon">
                                                            <i className="bx bx-link-external"></i>
                                                        </Button>
                                                    </a>
                                                </td>
                                                <td className="text-center">
                                                    <a href={val.ta} target="_blank" >
                                                        <Button variant="outline-info" size="sm" className="btn-icon">
                                                            <i className="bx bx-link-external"></i>
                                                        </Button>
                                                    </a>
                                                </td>
                                                <td className="text-center">
                                                    <a href={val.te} target="_blank" >
                                                        <Button variant="outline-info" size="sm" className="btn-icon">
                                                            <i className="bx bx-link-external"></i>
                                                        </Button>
                                                    </a>
                                                </td>
                                                <td className="text-center">
                                                    <a href={val.kn} target="_blank" >
                                                        <Button variant="outline-info" size="sm" className="btn-icon">
                                                            <i className="bx bx-link-external"></i>
                                                        </Button>
                                                    </a>
                                                </td>
                                                <td className="text-center">
                                                    <a href={val.bn} target="_blank" >
                                                        <Button variant="outline-info" size="sm" className="btn-icon">
                                                            <i className="bx bx-link-external"></i>
                                                        </Button>
                                                    </a>
                                                </td>
                                                <td className="text-center">
                                                    <a href={val.ml} target="_blank" >
                                                        <Button variant="outline-info" size="sm" className="btn-icon">
                                                            <i className="bx bx-link-external"></i>
                                                        </Button>
                                                    </a>
                                                </td>
                                                <td className="text-center">
                                                    <a href={val.or} target="_blank" >
                                                        <Button variant="outline-info" size="sm" className="btn-icon">
                                                            <i className="bx bx-link-external"></i>
                                                        </Button>
                                                    </a>
                                                </td>
                                                <td className="text-center">
                                                    <a href={val.pa} target="_blank" >
                                                        <Button variant="outline-info" size="sm" className="btn-icon">
                                                            <i className="bx bx-link-external"></i>
                                                        </Button>
                                                    </a>
                                                </td>
                                                <td className='text-center'>
                                                    <Switch
                                                        onChange={(e) => {
                                                            Togglechange(val.id, e, "status");
                                                        }}
                                                        checked={val.status === 1 ? true : false}
                                                        offColor="#C8C8C8"
                                                        onColor="#0093ed"
                                                        height={30}
                                                        width={70}
                                                        disabled={userPermission["DL-Rc-info"]?.edit != 1}
                                                        className="react-switch"
                                                        uncheckedIcon={
                                                            <div className="react-switch-off">OFF</div>
                                                        }
                                                        checkedIcon={
                                                            <div className="react-switch-on">ON</div>
                                                        }
                                                    />
                                                </td>
                                                <td className='text-center'>
                                                    <Link to={`/dl-rc-info/view/${val.id}`}>
                                                        <Button variant="outline-warning" size="sm" className="me-2 btn-icon"><i className='bx bx-show'></i></Button>
                                                    </Link>
                                                    {userPermission["DL-Rc-info"]?.delete == 1 && <Button variant="outline-danger" onClick={() => DeleteData(val.id)} size="sm" className="btn-icon"><i className='bx bx-trash-alt' ></i></Button>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })

                                }
                            </tbody>
                            {
                                isLoading == false && totalPageCount === 0 ? <tr>
                                    <td colSpan="100%" className="p-0">
                                        <div className='no-found'>
                                            <img src="../../not-found/image.svg" />
                                            <p>No Found RC/DL Info</p>
                                        </div>
                                    </td>
                                </tr> : ""
                            }
                        </Table>
                        {totalPageCount > size ? (
                            <div className="pagination-custom">
                                <Pagination
                                    showTitle={false}
                                    className="pagination-data"
                                    onChange={paginationData}
                                    total={totalPageCount}
                                    current={current}
                                    pageSize={size}
                                    showSizeChanger={false}
                                    itemRender={PrevNextArrow}
                                    onShowSizeChange={PerPageChange}
                                />
                            </div>
                        ) : (
                            ""
                        )}
                    </Card.Body>
                </Card>
            </div>
        </Layout>
    )
}

export default RCDLinfo