import React, { useEffect, useState } from 'react'
import { Row, Col, Card, Button, Form, Breadcrumb } from 'react-bootstrap';
import { SelectPicker } from "rsuite";
import { Link, useNavigate, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { API } from '../../App';
import Layout from '../../layout/Layout';

var News_Headline_Array = []
const NewsEdit = () => {
    const token = Cookies.get("fmljwt");
    const navigate = useNavigate()
    const [validated, setvalidated] = useState(false)
    const params = useParams()
    const [news, setnews] = useState("")
    const [style, setstyle] = useState("none")
    const [New_data, setNew_Data] = useState({
        news_headline_id: '',
    })
    const [isLoading, setIsLoading] = useState(false);

    const getdata = async (id) => {
        setIsLoading(true)
        try {
            const Form = new FormData()
            const Response = await API.post(`/get-news/${params.id || id}`, {}, { headers: { authorization: `${token}` } })
            setnews(Response.data.data.news.replaceAll(",", "\n"))
            setNew_Data({
                news_headline_id: Response.data.data.NewsHeadLineTitle.id
            })
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
        } finally {
            setIsLoading(false)
        }
    }
    const [NewsHeadline, setNewsHeadline] = useState([])
    const News_Headline_dropdown = async () => {
        const resut = await API.post(`/get-news/${params.id}`, {}, { headers: { authorization: `${token}` } });
        News_Headline_Array = []
        // News_Headline_Array.push({ label: "Select News Headline", value: "" })
        // resut.data.data.map((val, index) => {
        //     News_Headline_Array.push({ label: resut.data.data.NewsHeadLineTitle.title, value: resut.data.data.NewsHeadLineTitle.id })
        // })
        setNewsHeadline([{ label: resut.data.data.NewsHeadLineTitle.title, value: resut.data.data.NewsHeadLineTitle.id }])
    }

    useEffect(() => {
        getdata("")
        News_Headline_dropdown()
    }, [])

    const getcategory = async (e, name) => {
        setNew_Data({ ...New_data, [name]: e });
        getdata(e)
    };

    const changeinput = (e) => {
        setnews(e.target.value)
    }

    const save = async () => {
        setIsLoading(true);
        try {
            if (New_data.news_headline_id == "") {
                toast.error("Please Select News Headline")
            } else if (news == "") {
                setvalidated(true)
            }
            else {
                const Form = new FormData()
                Form.append('news_headline_id', New_data.news_headline_id)
                Form.append('news', news)
                const result = await API.post(`/update-news/${params.id}`, Form, { headers: { authorization: `${token}` } });
                if (result) {
                    toast.success("Data Update successfully");
                    navigate(`/news/view/${params.id}`)
                }
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    }
    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                {isLoading && <div className="loader "></div>}
                <h3>News Edit</h3>
                <Breadcrumb className="d-none d-sm-none d-md-none d-lg-block">
                    <Breadcrumb.Item >
                        <Link to="/"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item >
                        <Link to="/news">News</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>News Edit</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className="page-content">
                <Form noValidate validated={validated}>
                    <Card className="mb-4">
                        <Card.Body>
                            <Row>
                                <Col md={12}>
                                    <Form.Label htmlFor="newsheadline">News Headline </Form.Label>
                                    <SelectPicker
                                        cleanable={false}
                                        data={NewsHeadline}
                                        searchable={false}
                                        name="news_headline_id"
                                        value={New_data.news_headline_id}
                                        className="my-2"
                                        block
                                        placeholder="Select News Headline"
                                        onChange={(e) => getcategory(e, "news_headline_id")}
                                    />
                                </Col>
                                <Col sm={12}>
                                    <Form.Group className="form-group">
                                        <Form.Label>News</Form.Label>
                                        <Form.Control as="textarea" className="my-2" rows={20} value={news} onChange={(e) => { changeinput(e) }} required />
                                        <Form.Control.Feedback type="invalid">
                                            News Field Is Require
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Footer className="text-end">
                            <Button
                                variant="primary"
                                className="ms-3"
                                onClick={save}
                            >
                                Save
                            </Button>
                            <Link to={`/news/view/${params.id}`} className="ms-3">
                                <Button variant="secondary" >Cancel</Button>
                            </Link>
                            <Button variant="warning" className="ms-3" onClick={() => { setstyle("block") }}>Preview</Button>
                        </Card.Footer>
                    </Card>
                    <Card style={{ display: `${style}` }} className="mt-4">
                        <Card.Body>
                            <div className="content" dangerouslySetInnerHTML={{ __html: news }}></div>
                        </Card.Body>
                    </Card>
                </Form>
            </div>
        </Layout>
    )
}

export default NewsEdit