import React, { useContext, useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { Button, Form, Card, Table } from "react-bootstrap";
import Layout from '../../layout/Layout';
import Pagination from "rc-pagination";
import Fancybox from "../../Component/FancyBox";
import { API } from "../../App";
import $, { data } from "jquery";
import Swal from "sweetalert2";
import { SelectPicker } from "rsuite";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { AuthContext } from "../../AppContext";

var Category_Array = []
var Brand_Array = []
var vehicle_Array = []
var Page_array = [];
const VehicleVariantSpecification = () => {
    const { userPermission, token } = useContext(AuthContext)
    const [Data, setData] = useState([])
    const [perPage, setPerPage] = useState(10);
    const [size, setSize] = useState(perPage);
    const [current, setCurrent] = useState(1);
    const [PageHook, setPageHook] = useState([])
    const [CategoryName, setCategoryName] = useState([])
    const [BrandName, setBrandName] = useState([])
    const [VehicleName, setVehicleName] = useState([])
    const [loading, setloading] = useState(true)
    const [isLoading, setIsLoading] = useState(false);
    const [totalPageCount, setTotalPageCount] = useState('')
    const [query, setquery] = useState({
        VeriantID: "",
        categoryId: "",
        BrandId: "",
        VehicleID: ""
    });

    useEffect(() => {
        if (query.categoryId) {
            Brand_dropdown(query.categoryId)
        }
        if (query.BrandId) {
            Vehicle_dropdown(query.BrandId)
        }

    }, [query])

    const GetData = async () => {

        setIsLoading(true)
        try {
            const Form = new FormData();
            Form.append("limit", size);
            Form.append("page", current);
            Form.append("search", query.VeriantID);
            Form.append("vehicleId", query.VehicleID);
            const result = await API.post("/get_pricevariant", Form, { headers: { authorization: `${token}` } })
            setData(result.data.data)
            PageGetData()
            setIsLoading(false)
            Category_dropdown()
            setTotalPageCount(result.data.totalcount)
        } catch (error) {
            setIsLoading(false)
        } finally {
            setIsLoading(false)
        }

    }
    const PerPageChange = (value) => {
        setSize(value);
        const newPerPage = Math.ceil(totalPageCount / value);
        if (current > newPerPage) {
            setCurrent(newPerPage);
        }
    };

    const paginationData = (page, pageSize) => {
        setCurrent(page);
        setSize(pageSize);
    };

    const PrevNextArrow = (current, type, originalElement) => {
        if (type === "prev") {
            return <button className="paggination-btn">Previous</button>;
        }
        if (type === "next") {
            return <button className="paggination-btn">Next</button>;
        }
        return originalElement;
    };

    const PageGetData = async () => {
        var PageNumber = [10, 25, 50, 100]
        Page_array = []
        PageNumber.map((val, index) => {
            Page_array.push({ label: val, value: val })
        })
        setPageHook(Page_array)
    };

    const Category_dropdown = async () => {
        const Result = await API.post("/get_category_dropdown", {}, { headers: { authorization: `${token}` } })
        Category_Array = []
        Category_Array.push({ label: "Select Category", value: "" })
        Result.data.data.map((val) => {
            Category_Array.push({ label: val.category_name, value: val.id })
        })
        setCategoryName(Category_Array)
    }

    const Brand_dropdown = async (e) => {
        const Form = new FormData()
        Form.append("id", e)
        const Result = await API.post("/get_brand_dropdown", Form, { headers: { authorization: `${token}` } })
        Brand_Array = []
        Brand_Array.push({ label: "Select Brand", value: "" })
        Result.data.data.map((val) => {
            Brand_Array.push({ label: val.name, value: val.id })
        })
        setBrandName(Brand_Array)
    }

    const Vehicle_dropdown = async (e) => {

        const Form = new FormData()
        Form.append("brand_id", e)
        const Result = await API.post("/get_vehicleinfo_dropdown", Form, { headers: { authorization: `${token}` } })
        vehicle_Array = []
        vehicle_Array.push({ label: "Select Vehicle Name", value: "" })
        Result.data.data.map((val) => {
            vehicle_Array.push({ label: val.model_name, value: val.id })
        })
        setVehicleName(vehicle_Array)
    }


    let count = 10
    let swalCountdownInterval
    const DeleteData = async (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: "btn btn-danger btn-lg counter",
                cancelButton: "btn btn-primary btn-lg me-3",
            },
            buttonsStyling: false,
        });

        swalWithBootstrapButtons
            .fire({
                title: "Are you sure you want to delete?",
                text: "You won't be able to revert this!",
                imageUrl: '../../icon/alert.svg',
                imageWidth: 80,
                imageHeight: 80,
                confirmButtonText: "OK (10)",
                cancelButtonText: "Cancel",
                showCancelButton: true,
                reverseButtons: true,
                didOpen: () => {
                    $(".swal2-confirm").attr('disabled', true);
                    swalCountdownInterval = setInterval(function () {
                        count--
                        if (count < 1) {
                            $(".counter").text(`OK`)
                            $(".swal2-confirm").attr('disabled', false);
                            clearInterval(swalCountdownInterval)
                        } else {
                            $(".counter").text(`OK (${count})`)
                        }
                    }, 1000);
                }
            })
            .then(async (result) => {
                if (result.isConfirmed) {
                    await API.post(`/delete_specification_detsils/${id}`, {}, { headers: { authorization: `${token}` } });
                    GetData();
                    toast.success("Deleted Successfully");
                    setCurrent(1)
                } else {
                    count = 10
                    clearInterval(swalCountdownInterval)
                }
            });
    };

    const Seaching = async (e, name) => {
        setCurrent(1);
        if (name === "VeriantID") {
            setquery({ ...query, [e.target.name]: e.target.value });
        } else if (name === "") {
            GetData()
        } else {
            if (name == "categoryId") {
                setquery({
                    ...query,
                    [name]: e,
                    BrandId: '',
                    VehicleID: ''
                });
                setVehicleName([])
            } else
                if (name == "BrandId") {
                    setquery({
                        ...query,
                        [name]: e,
                        VehicleID: ""
                    })
                } else {
                    setquery({ ...query, [name]: e })
                }
        }
        // const Form = new FormData();
        // Form.append("VeriantID", name == "VeriantID" ? e.target.value : query.VeriantID);
        // Form.append("categoryId", name == "categoryId" ? e : query.categoryId);
        // Form.append("BrandId", name == "BrandId" ? e : query.BrandId);
        // Form.append("VehicleID", name == "VehicleID" ? e : query.VehicleID);
        // const result = await API.post("/Searching/Specification_Variant", Form, { headers: { authorization: `${token}` } });
        // setData(result.data.data);
    }

    useEffect(() => {
        console.log(query)
        GetData()
    }, [current, size, query])
    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3 className="my-1">Variant Specification</h3>
                <div className="page-heading-right">
                    <Form.Control
                        type="text"
                        name="VeriantID"
                        id=""
                        placeholder="Search Variant Name"
                        className="wv-200 my-1 ms-3"
                        onChange={(e) => Seaching(e, "VeriantID")}
                    />
                    <SelectPicker
                        cleanable={false}
                        data={CategoryName}
                        defaultValue={""}
                        className="wv-200 my-1 ms-3"
                        placeholder="Select Category"
                        placement="bottomEnd"
                        // value={search[2].search.status}
                        onChange={(e) => Seaching(e, "categoryId")}
                    />
                    <SelectPicker
                        cleanable={false}
                        data={BrandName}
                        value={query.BrandId}
                        className="wv-200 my-1 ms-3"
                        placeholder="Select Brand"
                        placement="bottomEnd"
                        // value={search[2].search.status}
                        onChange={(e) => Seaching(e, "BrandId")}
                    />
                    <SelectPicker
                        cleanable={false}
                        data={VehicleName}
                        value={query.VehicleID}
                        className="wv-200 my-1 ms-3"
                        placeholder="Select Vehicle Name"
                        placement="bottomEnd"
                        // value={search[2].search.status}
                        onChange={(e) => Seaching(e, "VehicleID")}
                    />
                    <SelectPicker
                        cleanable={false}
                        data={PageHook}
                        searchable={false}
                        // style={{ width: 224 }}
                        defaultValue={10}
                        className="wv-100 my-1 ms-3"
                        onChange={(e) => {
                            setSize(Number(e));
                            setCurrent(1);
                        }}
                    />
                    {userPermission["Vehicle-info2-Variant-Specification"]?.add==1&&<Link to="/vehicle-variant-specification/add" className="my-1 ms-3">
                        <Button variant="primary" value="create">Add New</Button>
                    </Link>}
                </div>
            </div>
            <div className="page-content">
                <Card>
                    <Card.Body className="position-relative">
                        {isLoading && <div className="loader table-loader" ></div>}
                        <Table bordered responsive>
                            <thead>
                                <tr>
                                    <th width="5%" className='text-center'>No</th>
                                    <th width="55%">Variant Name</th>
                                    <th width="20%">Fule Type</th>
                                    <th width="10%" className="text-center">Variant Image</th>
                                    <th width="10%" className='text-center' >Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    Data.map((val, i) => {
                                        return (
                                            <tr>
                                                <td className='text-center'>{(current === 1) ? i + 1 : current * size + i + 1 - size}</td>
                                                <td>{val.name}</td>
                                                <td>{val.fuel_type}</td>
                                                <td className="text-center">
                                                    <Fancybox>
                                                        <a href={val.image} data-fancybox="gallery">
                                                            <img src={val.image?val.image:"./avatar/dummy-img.svg"} 
                                                            onError={(e) => {
                                                                e.target.src = './avatar/dummy-img.svg'; 
                                                              }}className="hv-40 rounded-3" alt="" />
                                                        </a>
                                                    </Fancybox>
                                                </td>
                                                <td className='text-center'>
                                                    <Link to={`/vehicle-variant-specification/view/${val.id}`}>
                                                        <Button variant="outline-warning" size="sm" className="me-2 btn-icon"><i className='bx bx-show'></i></Button>
                                                    </Link>
                                                    {userPermission["Vehicle-info2-Variant-Specification"]?.delete==1&&<Button variant="outline-danger" size="sm" onClick={() => DeleteData(val.id)} className="btn-icon"><i className='bx bx-trash-alt' ></i></Button>
                                                }</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                            {
                                isLoading == false && totalPageCount === 0 ? <tr>
                                    <td colSpan="100%" className="p-0">
                                        <div className='no-found'>
                                            <img src="../../not-found/image.svg" />
                                            <p>No Found Variant Specification</p>
                                        </div>
                                    </td>
                                </tr> : ""
                            }
                        </Table>
                        {totalPageCount > size ? (
                            <div className="pagination-custom">
                                <Pagination
                                    showTitle={false}
                                    className="pagination-data"
                                    onChange={paginationData}
                                    total={totalPageCount}
                                    current={current}
                                    pageSize={size}
                                    showSizeChanger={false}
                                    itemRender={PrevNextArrow}
                                    onShowSizeChange={PerPageChange}
                                />
                            </div>
                        ) : (
                            ""
                        )}
                    </Card.Body>
                </Card>
            </div>
        </Layout>
    )
}

export default VehicleVariantSpecification