import React, { useContext, useEffect, useState } from "react";
import Layout from "../../layout/Layout";
import { Form, Card, Table, Button, Badge } from "react-bootstrap";
import SelectPicker from 'rsuite/SelectPicker';
import { Link } from "react-router-dom";
import Pagination from "rc-pagination";
import { deleteAffilationCity, getAffilationCityData, getAffilationState } from "../../service/apis";
import Swal from "sweetalert2";
import $ from "jquery";
import { AuthContext } from "../../AppContext";
import Fancybox from "../../Component/FancyBox";
import Switch from 'react-switch';
import { API } from "../../App";
import { toast } from "react-toastify";

const data = ['25', '50', "100"].map(
    item => ({ label: item, value: item })
);
const AffiliationCity = () => {
    const { userPermission, token } = useContext(AuthContext)
    const [allAffilationCityData, setAllAffilationCityData] = useState([]);
    const [allState, setAllState] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [textInput, setTextInput] = useState("");
    const [selectedState, setSelectedState] = useState("");
    const [current, setCurrent] = useState(1);
    const [limit, setLimit] = useState("25");
    const [selectIndex, setSelectIndex] = useState();
    const [isHidden, setIsHidden] = useState(true);

    const onChange = page => {
        setCurrent(page);
    };
    useEffect(() => {
        fetchAffilationState()

    }, [])
    useEffect(() => {
        fetchAffilationCityData()
    }, [selectedState, textInput, current, limit])

    const CityNamehandleShow = (index) => {
        (selectIndex == index) ? setSelectIndex() : setSelectIndex(index)
        setIsHidden(!isHidden);
    };


    const selectState = allState?.map(
        item => ({ label: item.state, value: item.state })
    );

    const Togglechange = async (id, e, name) => {
        // setIsLoading(true)
        console.log('first', id, e, name)
        var status;
        if (name === "is_popular") {
            status = e === true ? 1 : 0;
        }
        const Form = new FormData();
        Form.append("id", id);
        Form.append("is_popular", status);
        const result = await API.post("/affilation_city_update_status", Form, { headers: { authorization: `${token}` } });
        if (result.data.status) {
            toast.success("Status Update successfully");
            setIsLoading(false)
            setCurrent(current)
            // await Seaching(query.search, query.status, query.type);
            fetchAffilationCityData()
        } else {
            setIsLoading(false)
        }
    };
    //Apis
    const fetchAffilationState = async () => {
        // setIsLoading(true);
        try {
            const response = await getAffilationState()
            setAllState(response?.data?.data)
        } catch (error) {
            console.log(error)
        } finally {
            // setIsLoading(false);
        }
    }
    const fetchAffilationCityData = async () => {
        setIsLoading(true);
        try {
            const formData = new FormData()
            textInput && formData.append('search', textInput)
            selectedState && formData.append('state', selectedState)
            formData.append('limit', limit)
            formData.append('page', current)
            const response = await getAffilationCityData(formData)
            setAllAffilationCityData(response?.data?.data)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    }
    console.log(isLoading)
    let count = 10
    let swalCountdownInterval
    const handleDelete = async (id, lable, index) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: "btn btn-danger btn-lg counter",
                cancelButton: "btn btn-primary btn-lg me-3",
            },
            buttonsStyling: false,
        });

        swalWithBootstrapButtons
            .fire({
                title: "Are you sure you want to delete?",
                text: "You won't be able to revert this!",
                imageUrl: '../../icon/alert.svg',
                imageWidth: 80,
                imageHeight: 80,
                confirmButtonText: "OK (10)",
                cancelButtonText: "Cancel",
                showCancelButton: true,
                reverseButtons: true,
                didOpen: () => {
                    $(".swal2-confirm").attr('disabled', true);
                    swalCountdownInterval = setInterval(function () {
                        count--
                        if (count < 1) {
                            $(".counter").text(`OK`)
                            $(".swal2-confirm").attr('disabled', false);
                            clearInterval(swalCountdownInterval)
                        } else {
                            $(".counter").text(`OK (${count})`)
                        }
                    }, 1000);
                }
            })
            .then(async (result) => {
                if (result.isConfirmed) {
                    setIsLoading(true);
                    try {
                        deleteAffilationCity(id).then(() => {
                            setCurrent(1)
                            fetchAffilationCityData()
                        })

                    } catch (error) {
                        console.log(error)
                    } finally {
                        setIsLoading(false);
                    }
                } else {
                    count = 10
                    clearInterval(swalCountdownInterval)
                }
            });
    };

    return (
        <>
            <Layout sidebar={true}>
                <div className="page-heading">
                    <h3 className="my-1">Affiliation City</h3>
                    <div className="page-heading-right">
                        <Form.Control
                            type="text"
                            name="search"
                            onChange={(e) => setTextInput(e.target.value)}
                            placeholder="Search State | City Name"
                            className="wv-250 my-1 ms-3"
                        />
                        <SelectPicker
                            cleanable={false}
                            data={selectState}
                            onChange={(e) => setSelectedState(e)}
                            className="wv-200 my-1 ms-3"
                            placeholder="Select State"
                            placement="bottomEnd"
                        />
                        <SelectPicker
                            cleanable={false}
                            data={data}
                            searchable={false}

                            value={limit}
                            onChange={(e) => { setLimit(e); setCurrent(1) }}
                            className="wv-100 my-1 ms-3"
                            placeholder="Select Record"
                        />
                        {userPermission["Service-City"]?.add == 1 && <Link to="/affiliation-city/add" className="btn btn-primary my-1 ms-3">Add New</Link>
                        }                    </div>
                </div>
                <div className="page-content">
                    <Card>
                        <Card.Body className="position-relative">
                            {isLoading && <div className="loader table-loader" ></div>}
                            <Table bordered responsive>
                                <thead>
                                    <tr>
                                        <th width="5%" className="text-center">No</th>
                                        <th width="12%">State</th>
                                        <th width="12%">City Name</th>
                                        <th width="6%">Image</th>
                                        <th width="6%">Is Populer</th>
                                        <th width="49%">Pincode</th>
                                        <th width="10%" className="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allAffilationCityData?.data?.length > 0 ? allAffilationCityData?.data?.map((item, index) => {
                                        return (<tr key={index}>
                                            <td className="text-center">{(current === 1) ? index + 1 : current * limit + index + 1 - limit}</td>
                                            <td>{item?.state}</td>
                                            <td>{item?.city}</td>
                                            <td className="text-center">
                                                <Fancybox>
                                                    <a href={item?.image} data-fancybox="gallery">
                                                        <img src={item?.image || "./avatar/dummy-img.svg"}
                                                            onError={(e) => {
                                                                e.target.src = "./avatar/dummy-img.svg";
                                                            }}
                                                            className="hv-40 rounded-3" alt="" />
                                                    </a>
                                                </Fancybox>
                                            </td>
                                            <td>
                                                <Switch
                                                    onChange={(e) => {
                                                        Togglechange(item?.id, e, "is_popular");
                                                    }}
                                                    checked={item?.is_popular === 1 ? true : false}
                                                    offColor="#C8C8C8"
                                                    onColor="#0093ed"
                                                    height={30}
                                                    width={70}
                                                    disabled={userPermission["Service-Provider"]?.edit != 1}
                                                    className="react-switch"
                                                    uncheckedIcon={
                                                        <div className="react-switch-off">OFF</div>
                                                    }
                                                    checkedIcon={
                                                        <div className="react-switch-on">ON</div>
                                                    }
                                                />
                                            </td>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        {(index !== selectIndex) ? item?.pin_code_data?.slice(0, 6)?.map((pincode, i) => (
                                                            <Badge key={i} bg="primary" className="mx-1 mb-1 wv-100">
                                                                {pincode.pincode}
                                                            </Badge>
                                                        ))
                                                            : item?.pin_code_data?.map((pincode, i) => (
                                                                <Badge key={i} bg="primary" className="mx-1 mb-1 wv-100">
                                                                    {pincode.pincode}
                                                                </Badge>
                                                            ))}
                                                    </div>
                                                    {item?.pin_code_data?.length > 6 && <div className="border-start ms-3 ps-3" onClick={() => CityNamehandleShow(index)}>
                                                        <Button variant="info me-1" size="sm"> {index !== selectIndex ? 'Show' : 'Hide'}</Button>
                                                    </div>}
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                <Link to={`/affiliation-city/view/${item?.id}`} className="btn btn-outline-warning btn-sm btn-icon me-2"><i className='bx bx-show'></i></Link>
                                                {userPermission["Service-City"]?.delete == 1 && <Button variant="outline-danger" onClick={() => handleDelete(item.id)} size="sm" className="btn-icon"><i className='bx bx-trash-alt' ></i></Button>
                                                }</td>
                                        </tr>)
                                    }) :
                                        <tr>
                                            <td colSpan="100%" className="p-0">
                                                <div className='no-found'>
                                                    <img src="../../not-found/image.svg" />
                                                    <p>No Found Affiliation City</p>
                                                </div>
                                            </td>
                                        </tr>}
                                </tbody>
                            </Table>
                            <div className="pagination-custom">
                                {allAffilationCityData?.data?.length > 0 && allAffilationCityData?.total_record > limit && <Pagination
                                    className="pagination-data"
                                    onChange={onChange}
                                    current={current}
                                    total={allAffilationCityData?.total_record}
                                    pageSize={limit}
                                    showSizeChanger={false}
                                    showTitle={false}
                                />}
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </Layout>
        </>
    )
}

export default AffiliationCity