import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button, Form, Breadcrumb } from 'react-bootstrap';
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from '../../layout/Layout';
import { API } from "../../App";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

var StateNAmeArray = []
const FuelPriceEdit = () => {
  const params = useParams()
  const token = Cookies.get("fmljwt");
  const [validated, setvalidated] = useState(false)
  const [Sate_nameData, setSate_nameData] = useState([])
  const navigate = useNavigate()
  const [Data, setData] = useState([{
    petrol_price: "",
    diesel_price: "",
    cng_price: "",
    lpg_price: "",
  }])

  const SaveData = async (e) => {
    setData({ ...Data, [e.target.name]: e.target.value })
  }

  // const State_name_dropdown = async () => {
  //   const Result = await API.post("/allFulepricestate", {}, { headers: { authorization: `${token}` } })
  //   StateNAmeArray = []
  //   StateNAmeArray.push({ label: "Select Categories", value: "", role: "" })
  //   Result.data.map((val) => {
  //     StateNAmeArray.push({ label: val.state, value: val.id })
  //   })
  //   setSate_nameData(StateNAmeArray)
  // }

  // const CategoryHendler = async (e, name) => {
  //   setData({ ...Data, [name]: e });
  // };

  const Getview = async (Eid) => {
    const result = await API.post(`/get_single_fuelprice/${params.id}` , {} , {headers: { authorization: `${token}` }});
    setData({
      petrol_price:result.data.data.petrol_price,
      diesel_price:result.data.data.diesel_price,
      lpg_price:result.data.data.lpg_price,
      cng_price:result.data.data.cng_price,
    });
    // State_name_dropdown()
  };

  useEffect(() => {
    Getview()
  }, [])

  const Submite = async () => {
    if (Data.petrol_price == '' ||  Data.diesel_price == "" ||  Data.cng_price == "" ||  Data.lpg_price == "") {
      setvalidated(true)
    } else {
      const Result = await API.post(`/edit_fuel_price/${params.id}`, Data, { headers: { authorization: `${token}` } })
      toast.success("Data Update successfully");
      navigate(`/fuel-price/view/${params.id}`)
    }
  }
  return (
    <Layout sidebar={true}>
    <div className="page-heading">
      <h3>Fuel Price Edit</h3>
      <Breadcrumb className="d-none d-sm-none d-md-none d-lg-block">
        <Breadcrumb.Item >
          <Link to="/"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item >
          <Link to="/fuel-price">Fuel Price</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Fuel Price Edit</Breadcrumb.Item>
      </Breadcrumb>
    </div>

    <div className="page-content">
      <Form noValidate validated={validated}>
        <Card className="mb-4">
          <Card.Body>
            <Row>
              <Col md={3}>
                <Form.Label htmlFor="petrolprice">Petrol Price</Form.Label>
                <Form.Control
                  type="text"
                  name="petrol_price"
                  className="my-2"
                  value={Data.petrol_price}
                  onChange={SaveData}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Petrol Price Is Require
                </Form.Control.Feedback>
              </Col>
              <Col md={3}>
                <Form.Label htmlFor="dieselprice">Diesel Price</Form.Label>
                <Form.Control
                  type="text"
                  name="diesel_price"
                  className="my-2"
                  value={Data.diesel_price}
                  onChange={SaveData}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Diesel Price Field Is Require
                </Form.Control.Feedback>
              </Col>

              <Col md={3}>
                <Form.Label htmlFor="cngprice">CNG Price</Form.Label>
                <Form.Control
                  type="text"
                  name="cng_price"
                  className="my-2"
                  value={Data.cng_price}
                  onChange={SaveData}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  CNG Price Field Is Require
                </Form.Control.Feedback>
              </Col>

              <Col md={3}>
                <Form.Label htmlFor="lpgprice">LPG Price</Form.Label>
                <Form.Control
                  type="text"
                  name="lpg_price"
                  className="my-2"
                  value={Data.lpg_price}
                  onChange={SaveData}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  LPG Price Field Is Require
                </Form.Control.Feedback>
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer className="text-end">
            <Button variant="primary" onClick={Submite} className="me-3">Save</Button>
            <Link to={`/fuel-price/view/${params.id}`}>
              <Button variant="secondary">Cancel</Button>
            </Link>
          </Card.Footer>
        </Card>
      </Form>
    </div>
  </Layout>
  )
}

export default FuelPriceEdit