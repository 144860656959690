import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Row } from "react-bootstrap";
import Layout from '../../layout/Layout';
import { SelectPicker } from 'rsuite';
import Fancybox from "../../Component/FancyBox";
import { API } from '../../App';
import $ from "jquery";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import { AuthContext } from '../../AppContext';


var DropDownArr = []
var reloadId = [];
const VehicleBodyTypeView = () => {
  const navigate=useNavigate()
  const { userPermission, token } = useContext(AuthContext)
  const params = useParams();
  const [CategoryData, setCategoryData] = useState([])
  const [DropDropValue, setDropDropValue] = useState([])
  const [id, setid] = useState({ _id: params.id });
  const [Data, SetData] = useState({
    _id: "",
    category_id: "",
    name: "",
    status:"",
  });
  const [isLoading, setIsLoading] = useState(false);

  const Dropdown_Name = async () => {
    const Result = await API.post("/get/vehicle/bodytype", {}, { headers: { authorization: `${token}` } })
    DropDownArr = []
    reloadId = []
    // DropDownArr.push({label:"Select Title" , value:"" })
    Result.data.data.data.map((val, i) => {
      DropDownArr.push({ label: val.name, value: val.id })
      reloadId.push(val.id)
    })
    setDropDropValue(DropDownArr)
  }

  const Getview = async (Eid) => {
    const result = await API.get(`/vehicle/bodytype/${Eid !== "" ? Eid : id._id}`, { headers: { authorization: `${token}` } });
    SetData({
      _id: result.data.data.id,
      category_id: result.data.data.category.category_name, 
      name: result.data.data.name,
      image: result.data.data.image,
      status: result.data.data.status,
    });
  };

  const selectpickerData = (e) => {
    Getview(e);
    setid({ _id: e });
  };

  useEffect(() => {
    Dropdown_Name()
    Getview("")
  }, [])


  let count = 10
  let swalCountdownInterval
  const Deleteproject = async (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-danger btn-lg counter",
        cancelButton: "btn btn-primary btn-lg me-3",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure you want to delete?",
        text: "You won't be able to revert this!",
        imageUrl: '../../icon/alert.svg',
        imageWidth: 80,
        imageHeight: 80,
        confirmButtonText: "OK (10)",
        cancelButtonText: "Cancel",
        showCancelButton: true,
        reverseButtons: true,
        didOpen: () => {
          $(".swal2-confirm").attr('disabled', true);
          swalCountdownInterval = setInterval(function () {
            count--
            if (count < 1) {
              $(".counter").text(`OK`)
              $(".swal2-confirm").attr('disabled', false);
              clearInterval(swalCountdownInterval)
            } else {
              $(".counter").text(`OK (${count})`)
            }
          }, 1000);
        }
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          const ind = reloadId.indexOf(Data._id)
          reloadId.splice(ind, 1)
          const formdata = new FormData()
          formdata.append("id", Data._id)
          setIsLoading(true)
          const result = await API.post("/delete/vehicle/bodytype", formdata, { headers: { authorization: `${token}` } });
          navigate("/vehicle-body-type")
          setIsLoading(false)
        } else {
          count = 10
          clearInterval(swalCountdownInterval)
        }
      });
  };
  return (
    <Layout sidebar={true}>
    <div className="page-heading">
      <h3><Link to="/vehicle-body-type" className='btn btn-primary btn-icon-lg me-3'><i className='bx bxs-left-arrow-alt'></i></Link>Vehicle Body Type View</h3>
      <div className="page-heading-right">
        <SelectPicker data={DropDropValue} value={Data._id} cleanable={false} className="wv-200 my-1 ms-3" onChange={(e) => selectpickerData(e)} placeholder="Select Body Type" placement="bottomEnd" />
        {userPermission["Vehicle-info2-Body-Types"]?.edit==1&&<Link to={`/vehicle-body-type/edit/${id._id}`}>
          <Button variant="primary ms-3 my-1" value="edit">Edit</Button>
        </Link>}
        {userPermission["Vehicle-info2-Body-Types"]?.delete==1&&<Button variant="danger ms-3 my-1 btn-icon-lg" type="button" onClick={(i) => Deleteproject()}><i className="bx bx-trash-alt"></i></Button>
     } </div>
    </div>
    <div className='page-content'>
      <Card>
        <Card.Body>
          <Row>
            <Col md={4}>
              <div className='mb-4'>
                <p className='mb-0 fw-bold'>Category</p><span>{Data.category_id}</span>
              </div>
            </Col>
            <Col md={4}>
              <div className='mb-4'>
                <p className='mb-0 fw-bold'>Name</p><span>{Data.name}</span>
              </div>
            </Col>
            <Col md={4}>
              <div className="mb-4">
                <p className="mb-1 fw-bold">Image</p>
                <Fancybox>
                  <a data-fancybox="gallery" href={Data.image}>
                    <img
                      src={Data.image?Data.image:"/../avatar/dummy-img.svg"}
                      className="hv-40 rounded-3"
                      onError={(e) => {
                        e.target.src = '/../avatar/dummy-img.svg'; 
                      }}
                      alt="Image"
                    />
                  </a>
                </Fancybox>
              </div>
            </Col>
            <Col md={4}>
              <div className='mb-4'>
                <p className='mb-0 fw-bold'>Status</p><span>{Data.status == 1 ? "On" : "Off"}</span>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  </Layout>
  )
}

export default VehicleBodyTypeView