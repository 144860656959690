import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Form,
  Breadcrumb,
} from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../../layout/Layout";
import { API } from "../../App";
import { toast } from "react-toastify";
import { useEffect } from "react";
import Cookies from "js-cookie";

const TagsAdd = () => {
  const token = Cookies.get("fmljwt");
  const params = useParams();
  const [validated, setvalidated] = useState(false);
  const navigate = useNavigate();
  const [Data, setData] = useState({ name: "" });
  const [isLoading, setIsLoading] = useState(false);

  const Getview = async (Eid) => {
    setIsLoading(true);
    try {
      if (params.id) {
        const result = await API.post(
          `/get-tag/${params.id}`,
          {},
          { headers: { authorization: `${token}` } }
        );
        setData({
          id: result.data.data.id,
          name: result.data.data.name,
        });
      } else {
        setData({
          id: "",
          name: "",
        });
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    Getview();
  }, []);

  const SaveData = async (e) => {
    setData({ ...Data, [e.target.name]: e.target.value });
  };

  const Submite = async () => {
    console.log('first')
    setIsLoading(true);
    try {
      if (params.id) {
        const Result = await API.post(`/update-tag/${params.id}`, Data, {
          headers: { authorization: `${token}` },
        });
        if (Result) {
          toast.success("Data Update successfully");
          navigate(`/tags/view/${params.id}`);
        }
      } else {
        if (Data.name == "") {
          setvalidated(true);
        } else {
          const Result = await API.post(
            `/create-tag`,
            { name: Data.name },
            {
              headers: { authorization: `${token}` },
            }
          );
          if (Result) {
            toast.success("Data Saved successfully");
            navigate(`/tags`);
          }
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3> Tags {params.id ? "Edit" : "Add"}</h3>
        <Breadcrumb className="d-none d-sm-none d-md-none d-lg-block">
          <Breadcrumb.Item>
            <Link to="/">
              <i className="bx bx-home-alt me-2 fs-5"></i> Home
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/tags">Tags</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>
            Tags {params.id ? "Edit" : "Add"}
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <div className="page-content">
        <Form noValidate validated={validated}>
          <Card className="mb-4">
            <Card.Body>
              <Row>
                <Col md={6}>
                  <Form.Label htmlFor="name">Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    className="my-2"
                    value={Data.name}
                    onChange={SaveData}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Tags Name Field Is Require
                  </Form.Control.Feedback>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer className="text-end">
              <Button variant="primary" onClick={Submite} className="me-3">
                Save
              </Button>
              <Link to={params.id ? `/tags/view/${params.id}` : `/tags`}>
                <Button variant="secondary">Cancel</Button>
              </Link>
            </Card.Footer>
          </Card>
        </Form>
      </div>
    </Layout>
  );
};

export default TagsAdd;
