import React, { useContext, useEffect, useState } from 'react';
import Layout from "../../layout/Layout";
import { Form, Card, Table, Row, Col, Button, Breadcrumb, InputGroup } from "react-bootstrap";
import SelectPicker from 'rsuite/SelectPicker';
import { Link } from "react-router-dom";
import { affilationProgramStatusUpdate, editDynamicDropdown, getDynamicDropdown } from '../../service/apis';
import { AuthContext } from '../../AppContext';
import Select from 'react-select';
import { toast } from 'react-toastify';

let _response = {}


const AffiliationData = () => {
    const { userPermission } = useContext(AuthContext)
    const [allDropdown, setAllDropdown] = useState([]);
    const [displayData, setDisplayData] = useState([]);
    const [fieldName, setFieldName] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const [sameInputValue, setSameInputValue] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [state, setState] = useState("");
    useEffect((item) => {
        fetchDynamicDropdown()
    }, [])


    //  All Dropdown
    const affiliationPlaces = [];
    allDropdown?.affiliation_place?.map((item) =>
        affiliationPlaces.push({ label: item.name, value: item.id })
    );

    const serviceCategorys = [];
    allDropdown?.service_category?.map((item) =>
        serviceCategorys.push({ label: item.name, value: item.id })
    );

    const serviceProviders = [];
    allDropdown?.service_providers?.map((item) =>
        serviceProviders.push({ label: item.name, value: item.id })
    );

    const affiliationServices = [];
    allDropdown?.affiliation_services?.map((item) =>
        affiliationServices.push({ label: item.name, value: item.id })
    );

    const adTypes = [];
    allDropdown?.ad_type?.map((item) =>
        adTypes.push({ label: item.name, value: item.id })
    );

    const utmTerms = [];
    allDropdown?.utm_term?.map((item) =>
        utmTerms.push({ label: item.name, value: item.name })
    );

    // Fetch apis
    const fetchDynamicDropdown = async () => {
        setIsLoading(true);
        try {
            const response = await getDynamicDropdown()
            _response = response?.data?.data
            setAllDropdown(response?.data?.data);
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    }
    const updateDynamicDropdown = async (payload) => {
        setIsLoading(true);
        try {
            const response = await editDynamicDropdown(payload).then((item) => { toast?.success(item?.data?.message) })
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    }

    // Value Change
    const handleChange = (e, index, element) => {
        setValidated(false)
        setInputValue(e)
        setSameInputValue(false)
        const updatedData = [...displayData];
        updatedData[index][element] = e
        setDisplayData(updatedData);
    }

    // Add new row
    const handleAddNew = () => {
        setValidated(false)
        setSameInputValue(false)
        setDisplayData([...displayData, { name: "" }])
    }
    function includesName(array, name) {
        console.log('array,name', array,name)
        let count = 0;
        for (const item of array) {
          if (item.name && item.name.toLowerCase() === name.toLowerCase()) {
            count++;
            if (count > 1) {
              return true;
            }
          }
        }
        return false;

      }

    const handleUpdate = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === true&&!includesName(displayData,inputValue)) {
        setSameInputValue(false)
            event.stopPropagation();
            console.log('first', includesName(displayData,inputValue))
            if (fieldName == "utm_term") {
                const { data } = await getDynamicDropdown()
                updateDynamicDropdown({ field_name: fieldName, data_value: displayData?.length > 0 && JSON.stringify(displayData), old_value: data?.data?.utm_term.length > 0 && JSON.stringify(data?.data?.utm_term) }).then(() => { setDisplayData([]); fetchDynamicDropdown() })

                // updateDynamicDropdown({field_name:fieldName,data_value:displayData?.length>0&&JSON.stringify(displayData),old_value:allDropdown?.utm_term.length>0&&JSON.stringify(allDropdown?.utm_term)})

            } else {
                updateDynamicDropdown({ field_name: fieldName, data_value: displayData?.length > 0 && JSON.stringify(displayData) }).then(() => { setDisplayData([]); fetchDynamicDropdown() })
            }
        }
        if (includesName(displayData,inputValue)) {
            setSameInputValue(true)
        }
        setValidated(true)
    }

    // Delete data
    const handleDelete = (index) => {
        displayData?.length > 0 && setDisplayData(displayData?.filter((el, i) => i != index))
    }
    // is new & is web
    const handleStatusChange = (status, id, key) => {
        affilationProgramStatusUpdate(status.target.checked, id, key).then(() => {
            toast.success("status Update successfully");
        }
        );
    };
    return (
        <>
            <Layout sidebar={true}>
                {isLoading && <div className="loader" ></div>}
                <div className="page-heading">
                    <h3><Link to="/affiliation" className='btn btn-primary btn-icon-lg me-3'><i className='bx bxs-left-arrow-alt'></i></Link>Affiliation Data</h3>
                    <Breadcrumb className="d-none d-sm-none d-md-none d-lg-block">
                        <Breadcrumb.Item >
                            <Link to="/"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item >
                            <Link to="/affiliation">Affiliation</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Affiliation Data Add</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className="page-content">
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col lg={3} md={4}>
                                    <Form.Label>Place</Form.Label>
                                    <Row className='gx-3 my-2'>
                                        <Col lg={10}>
                                            <SelectPicker
                                                cleanable={false}
                                                data={affiliationPlaces}
                                                block
                                                placeholder="Select Place"
                                            />
                                        </Col>
                                        <Col lg={2} className='text-center'>
                                            <Button variant="outline-info btn-icon-lg" onClick={() => {
                                                setState("Place"); setDisplayData(allDropdown?.affiliation_place); setFieldName("affiliation_place")
                                            }}><i className='bx bxs-pencil'></i></Button>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={3} md={4}>
                                    <Form.Label>Services</Form.Label>
                                    <Row className='gx-3 my-2'>
                                        <Col lg={10}>
                                            <SelectPicker
                                                cleanable={false}
                                                data={affiliationServices}
                                                block
                                                placeholder="Select Services"
                                            />
                                        </Col>
                                        <Col lg={2} className='text-center'>
                                            <Button variant="outline-info btn-icon-lg" onClick={() => {
                                                setState("Services"); setDisplayData(allDropdown?.affiliation_services); setFieldName("affiliation_services")
                                            }}><i className='bx bxs-pencil'></i></Button>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={3} md={4}>
                                    <Form.Label>Services Provider</Form.Label>
                                    <Row className='gx-3 my-2'>
                                        <Col lg={10}>
                                            <SelectPicker
                                                cleanable={false}
                                                data={serviceProviders}
                                                block
                                                placeholder="Select Services Provider"
                                            />
                                        </Col>
                                        <Col lg={2} className='text-center'>
                                            <Button variant="outline-info btn-icon-lg" onClick={() => {
                                                setState("Services Provider"); setDisplayData(allDropdown?.service_providers); setFieldName("service_providers")
                                            }}><i className='bx bxs-pencil'></i></Button>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={3} md={4}>
                                    <Form.Label>AD Type</Form.Label>
                                    <Row className='gx-3 my-2'>
                                        <Col lg={10}>
                                            <SelectPicker
                                                cleanable={false}
                                                data={adTypes}
                                                block
                                                placeholder="Select AD Type"
                                            />
                                        </Col>
                                        <Col lg={2} className='text-center'>
                                            <Button variant="outline-info btn-icon-lg" onClick={() => {
                                                setState("AD Type"); setDisplayData(allDropdown?.ad_type); setFieldName("ad_type")
                                            }}><i className='bx bxs-pencil'></i></Button>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={3} md={4}>
                                    <Form.Label>Service Category</Form.Label>
                                    <Row className='gx-3 my-2'>
                                        <Col lg={10}>
                                            <SelectPicker
                                                cleanable={false}
                                                data={serviceCategorys}
                                                block
                                                placeholder="Select Service Category"
                                            />
                                        </Col>
                                        <Col lg={2} className='text-center'>
                                            <Button variant="outline-info btn-icon-lg" onClick={() => {
                                                setState("Services category"); setDisplayData(allDropdown?.service_category); setFieldName("service_category")
                                            }}><i className='bx bxs-pencil'></i></Button>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={3} md={4}>
                                    <Form.Label>UTM Term</Form.Label>
                                    <Row className='gx-3 my-2'>
                                        <Col lg={10}>
                                            <SelectPicker
                                                cleanable={false}
                                                data={utmTerms}
                                                block
                                                placeholder="Select UTM Term"
                                            />
                                        </Col>
                                        <Col lg={2} className='text-center'>
                                            <Button variant="outline-info btn-icon-lg" onClick={() => {
                                                const _new_data = allDropdown?.utm_term
                                                console.log("--_response", _response);
                                                setDisplayData(_new_data);
                                                setState("UTM Term")
                                                setFieldName("utm_term")
                                            }
                                            }><i className='bx bxs-pencil'></i></Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Form noValidate validated={validated} onSubmit={handleUpdate}>
                                <Row className="d-flex justify-content-center mt-3">
                                    {displayData?.length > 0 && displayData?.map((item, index) => {
                                        let data = [
                                            { label: "place array", value: 1 },
                                            { label: "home slider", value: 2 },
                                            { label: "rc detaits", value: 3 },
                                            { label: "is group", value: 4 },
                                            { label: "is slider show", value: 5 }
                                        ]
                                        let selected = item?.is_selected?.split(",")?.map((item) => data?.find((el) => el.value == item))
                                        let showLimitData = (item?.is_selected && item?.is_selected != "") && item?.is_selected?.split(",")
                                        data.push({ label: "is group", value: 4, isdisabled: showLimitData && showLimitData.length ? false : true })
                                        let isShowLimit = (showLimitData && showLimitData?.length && showLimitData.includes("4")) ? false : true
                                        return (
                                            <>


                                                {state == "Place" && <>
                                                    <Col lg={3} key={index}>
                                                        <Form.Label>{state}</Form.Label>
                                                        <Form.Control type="text" className='my-2' value={item?.name} onChange={(e) => handleChange(e.target.value, index, "name")}
                                                            disabled={userPermission["Affilation-Data"]?.add != 1 || userPermission["Affilation-Data"]?.edit != 1} required />
                                                    </Col>

                                                    <Col lg={3}>
                                                        <Form.Label>Place Type</Form.Label>
                                                        <Select
                                                            closeMenuOnSelect={false}
                                                            name="Place_Type"
                                                            // isDisabled={isAllCity}
                                                            value={selected}
                                                            onChange={(e) => { handleChange(e?.map((item) => { return item?.value }).join(","), index, "is_selected") }}
                                                            isClearable={true}
                                                            placeholder="Select Place Type"
                                                            className={`customMulSelect my-2`}
                                                            classNamePrefix="react-select"
                                                            isMulti
                                                            options={[
                                                                { label: "place array", value: 1 },
                                                                { label: "home slider", value: 2 },
                                                                { label: "rc detaits", value: 3 },
                                                                { label: "is group", value: 4, isdisabled: showLimitData && showLimitData.length ? false : true },
                                                                { label: "is slider show", value: 5 }
                                                            ]}
                                                            isOptionDisabled={(option) => option.isdisabled}
                                                        // required
                                                        />
                                                    </Col>
                                                    <Col lg={2}>
                                                        <Form.Label>Limit</Form.Label>
                                                        <Form.Control type="text" className='my-2' disabled={isShowLimit} value={item?.place_limit} onChange={(e) => handleChange(e.target.value, index, "place_limit")} required />
                                                    </Col>
                                                    <Col lg={1}>
                                                        <Form.Label>Is Category</Form.Label>
                                                        <Form.Check type="switch" onChange={(e) => { handleChange(e.target.checked, index, "is_category"); handleStatusChange(e, item?.id, "is_category") }} checked={item?.is_category == 1} className='my-2' />
                                                    </Col>
                                                    <Col lg={1}>
                                                        <Form.Label>Is New</Form.Label>
                                                        <Form.Check type="switch" onChange={(e) => { handleChange(e.target.checked, index, "is_new"); handleStatusChange(e, item?.id, "is_new") }} checked={item?.is_new == 1} className='my-2' />
                                                    </Col>
                                                    <Col lg={1}>
                                                        <Form.Label>Is Website</Form.Label>
                                                        <Form.Check type="switch" onChange={(e) => { handleChange(e.target.checked, index, "is_web"); handleStatusChange(e, item?.id, "is_web") }} checked={item?.is_web == 1} className='my-2' />
                                                    </Col>
                                                    <Col lg={1} className='align-self-end text-end'>
                                                        {userPermission["Affilation-Data"]?.delete == 1 && (fieldName != "utm_term" && <Button variant="danger mb-2 btn-icon-lg" onClick={() => handleDelete(index)}><i className="bx bx-x"></i></Button>)}
                                                    </Col>
                                                </>}

                                                {(state != "Place") && <Col lg={7} key={index}>
                                                    <Form.Label>{state}</Form.Label>
                                                    <InputGroup className="my-2">
                                                        <Form.Control type="text" value={item?.name} onChange={(e) => handleChange(e.target.value, index, "name")} disabled={userPermission["Affilation-Data"]?.add != 1 || userPermission["Affilation-Data"]?.edit != 1} required />
                                                        {userPermission["Affilation-Data"]?.delete == 1 && (fieldName != "utm_term" && <Button variant="danger" onClick={() => handleDelete(index)}><i className="bx bx-x"></i></Button>)}
                                                    </InputGroup>
                                                </Col>}
                                            </>
                                        )
                                    })}
                                    {displayData?.length > 0 &&
                                    <>
                                   { sameInputValue&&<div className='text-danger'>{state} Already Added</div>}
                                     <Col lg={7}>
                                        <div className="d-flex justify-content-between my-3">
                                            {(userPermission["Affilation-Data"]?.add == 1 || userPermission["Affilation-Data"]?.edit == 1) && <Button variant="primary" type='submit'>Update Now</Button>}
                                            {userPermission["Affilation-Data"]?.add == 1 && (fieldName != "utm_term" && <Button variant="primary" onClick={handleAddNew}>Add New</Button>)}
                                        </div>
                                    </Col></>}

                                    {/* {displayData.length > 0 && <Col lg={12}>
                                        <div className="d-flex justify-content-between my-3">
                                            {userPermission["Affilation-Data"]?.add==1&&(fieldName != "utm_term" && <Button variant="primary" onClick={handleAddNew}>Add New</Button>)}
                                            {(userPermission["Affilation-Data"]?.add==1||userPermission["Affilation-Data"]?.edit==1)&&<Button variant="primary" type='submit'>Update Now</Button>}
                                        </div>
                                    </Col>} */}
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </div>
            </Layout>
        </>
    )
}

export default AffiliationData;