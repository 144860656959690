import React, { useContext, useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { Button, Form, Card, Table } from "react-bootstrap";
import Layout from '../../layout/Layout';
import Pagination from "rc-pagination";
import { API } from "../../App";
import $ from "jquery";
import Swal from "sweetalert2";
import { SelectPicker } from "rsuite";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { AuthContext } from "../../AppContext";

var State_name_Array = []
var Page_array = [];
const TrafficRule = () => {
  const { userPermission, token } = useContext(AuthContext)

  const [Data, setData] = useState([])
  const [perPage, setPerPage] = useState(10);
  const [size, setSize] = useState(perPage);
  const [current, setCurrent] = useState(1);
  const [PageHook, setPageHook] = useState([])
  const [Sate_nameData, setSate_nameData] = useState([])
  const [loading, setloading] = useState(true)
  const [totalPageCount, setTotalPageCount] = useState('')
  const [isLoading, setIsLoading] = useState(false);

  const [query, setquery] = useState({
    search: "",
    state: "",
  });
  const GetData = async () => {
    setIsLoading(true)
    try {
      const result = await API.post("/get_all_traffic_rule", { limit: size, page: current }, { headers: { authorization: `${token}` } })
      setData(result.data.data)
      PageGetData()
      State_name_dropdown()
      setTotalPageCount(result.data.totalcount)
    } catch (error) {
      toast.error(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  const PerPageChange = (value) => {
    setSize(value);
    const newPerPage = Math.ceil(totalPageCount / value);
    if (current > newPerPage) {
      setCurrent(newPerPage);
    }
  };

  const paginationData = (page, pageSize) => {
    setCurrent(page);
    setSize(pageSize);
  };

  const PrevNextArrow = (current, type, originalElement) => {
    if (type === "prev") {
      return <button className="paggination-btn">Previous</button>;
    }
    if (type === "next") {
      return <button className="paggination-btn">Next</button>;
    }
    return originalElement;
  };

  const PageGetData = async () => {
    var PageNumber = [10, 25, 50, 100]
    Page_array = []
    PageNumber.map((val, index) => {
      Page_array.push({ label: val, value: val })
    })
    setPageHook(Page_array)
  };

  const State_name_dropdown = async () => {
    const Result = await API.post("/get_traffic_states_dropdown", {}, { headers: { authorization: `${token}` } })
    State_name_Array = []
    State_name_Array.push({ label: "Select State", value: "", role: "" })
    Result.data.data.map((val) => {
      State_name_Array.push({ label: `${val.state_code + "-" + val.state_name}`, value: val.id })
    })
    setSate_nameData(State_name_Array)
  }

  let count = 10
  let swalCountdownInterval
  const DeleteData = async (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-danger btn-lg counter",
        cancelButton: "btn btn-primary btn-lg me-3",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure you want to delete?",
        text: "You won't be able to revert this!",
        imageUrl: '../../icon/alert.svg',
        imageWidth: 80,
        imageHeight: 80,
        confirmButtonText: "OK (10)",
        cancelButtonText: "Cancel",
        showCancelButton: true,
        reverseButtons: true,
        didOpen: () => {
          $(".swal2-confirm").attr('disabled', true);
          swalCountdownInterval = setInterval(function () {
            count--
            if (count < 1) {
              $(".counter").text(`OK`)
              $(".swal2-confirm").attr('disabled', false);
              clearInterval(swalCountdownInterval)
            } else {
              $(".counter").text(`OK (${count})`)
            }
          }, 1000);
        }
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          await API.post(`/delete_traffic_rule/${id}`, {}, { headers: { authorization: `${token}` } });
          GetData();
          toast.success("Deleted successfully")
          setCurrent(1)
        } else {
          count = 10
          clearInterval(swalCountdownInterval)
        }
      });
  };

  const Seaching = async (search, state) => {
    setIsLoading(true)
    try {
      // setCurrent(1);
      const result = await API.post("/get_all_traffic_rule", { search: search, state: state, limit: size, page: current }, { headers: { authorization: `${token}` } });
      setData(result.data.data);
      setTotalPageCount(result.data.totalcount)
    } catch (error) {
      toast.error(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (query.search != "" || query.state != "") {
      Seaching(query.search, query.state)
    } else {
      GetData()
    }
  }, [current, size, query.search, query.state,])

  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3 className="my-1">Traffic Rule</h3>
        <div className="page-heading-right">
          <Form.Control
            type="text"
            name="search"
            id=""
            placeholder="Search Offence"
            className="wv-200 my-1 ms-3"
            // value={search[2].search.name}
            onChange={(e) => { setquery({ ...query, search: e.target.value }); setCurrent(1) }}
          />
          <SelectPicker
            cleanable={false}
            data={Sate_nameData}
            defaultValue={""}
            className="wv-200 my-1 ms-3"
            placeholder="Select State Name"
            placement="bottomEnd"
            block
            onChange={(e) => { setquery({ ...query, state: e }); setCurrent(1) }}
          />
          <SelectPicker
            cleanable={false}
            data={PageHook}
            searchable={false}
            // style={{ width: 224 }}
            defaultValue={10}
            className="wv-100 my-1 ms-3"
            onChange={(e) => {
              setSize(Number(e));
              setCurrent(1);
            }}
          />
          {userPermission["Traffic-Language"]?.view==1 &&<Link to="/traffic-rule/language" className="my-1 ms-3">
            <Button variant="primary" value="create">Add Language</Button>
          </Link>}
          {userPermission["Traffic-Rules"]?.add==1 &&<Link to="/traffic-rule/add" className="my-1 ms-3">
            <Button variant="primary" value="create">Add New</Button>
          </Link>}
        </div>
      </div>
      <div className="page-content">
        <Card>
          <Card.Body className="position-relative">
            {isLoading && <div className="loader table-loader" ></div>}
            <Table bordered responsive>
              <thead>
                <tr>
                  <th width="5%" className='text-center'>No</th>
                  <th width="15%">State Name</th>
                  <th width="30%" >Offence</th>
                  <th width="40%" >Penalty</th>
                  <th width="10%" className='text-center' >Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  Data.map((val, i) => {
                    return (
                      <tr>
                        <td className='text-center'>{(current === 1) ? i + 1 : current * size + i + 1 - size}</td>
                        <td>{val.traffic_state_details.state_name}</td>
                        <td>{val.offence}</td>
                        <td>{val.penalty}</td>
                        <td className='text-center'>
                          <Link to={`/traffic-rule/view/${val.id}`}>
                            <Button variant="outline-warning" size="sm" className="me-2 btn-icon"><i className='bx bx-show'></i></Button>
                          </Link>
                          {userPermission["Traffic-Rules"]?.delete==1 &&<Button variant="outline-danger" onClick={() => DeleteData(val.id)} size="sm" className="btn-icon"><i className='bx bx-trash-alt' ></i></Button>
                  }
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
              {
                isLoading == false && totalPageCount === 0 ? <tr>
                  <td colSpan="100%" className="p-0">
                    <div className='no-found'>
                      <img src="../../not-found/image.svg" />
                      <p>No Found Traffic Rule</p>
                    </div>
                  </td>
                </tr> : ""
              }
            </Table>
            {totalPageCount > size ? (
              <div className="pagination-custom">
                <Pagination
                  showTitle={false}
                  className="pagination-data"
                  onChange={paginationData}
                  total={totalPageCount}
                  current={current}
                  pageSize={size}
                  showSizeChanger={false}
                  itemRender={PrevNextArrow}
                  onShowSizeChange={PerPageChange}
                />
              </div>
            ) : (
              ""
            )}
          </Card.Body>
        </Card>
      </div>
    </Layout>
  )
}

export default TrafficRule