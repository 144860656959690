import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Row } from "react-bootstrap";
import Layout from '../../layout/Layout';
import { SelectPicker } from 'rsuite';
import Fancybox from "../../Component/FancyBox";
import { API } from '../../App';
import $ from "jquery";
import Swal from "sweetalert2";
import { toast } from 'react-toastify';
import { AuthContext } from '../../AppContext';

var reloadId = [];
const RCDLinfoView = () => {
    const { userPermission, token } = useContext(AuthContext)
    const params = useParams();
    const [DropDropValue, setDropDropValue] = useState([])
    const [id, setid] = useState({ id: params.id });
    const navigate = useNavigate()
    const [Data, SetData] = useState({
        id: "",
        type: "",
        title: "",
        en: "",
        hi: "",
        mr: "",
        gu: "",
        kn: "",
        ta: "",
        te: "",
        bn: "",
        ml: "",
        or: "",
        pa: "",
        thumb_image: "",
        status: "",
    });
    const [isLoading, setIsLoading] = useState(false);

    const Dropdown_Name = async () => {
        const Result = await API.post("/get_all_rcdl_info_dropdown", {}, { headers: { authorization: `${token}` } })
        setDropDropValue(Result.data.data.map((val, i) => {
            return { label: val.title, value: val.id }
        }))
    }

    const Getview = async (Eid) => {
        setIsLoading(true)
        const result = await API.post(`/get_rcdl_info/${Eid !== "" ? Eid : id.id}`, {}, { headers: { authorization: `${token}` } });
        if (result.data.status) {
            SetData({
                id: result.data.data.id,
                type: result.data.data.type,
                title: result.data.data.title,
                en: result.data.data.en,
                hi: result.data.data.hi,
                mr: result.data.data.mr,
                gu: result.data.data.gu,
                kn: result.data.data.kn,
                ta: result.data.data.ta,
                te: result.data.data.te,
                bn: result.data.data.bn,
                ml: result.data.data.ml,
                or: result.data.data.or,
                pa: result.data.data.pa,
                thumb_image: result.data.data.thumb_image,
                status: result.data.data.status,
            });
            setid({ id: result.data.data.id })
            Dropdown_Name()
            setIsLoading(false)
        } else {
            toast.error('Something Went wrong!!!')
        }
    };

    const selectpickerData = (e) => {
        setid({ id: e });
        Getview(e);
        navigate(`/dl-rc-info/view/${e}`)
    };

    useEffect(() => {
        Getview("")
    }, [])


    let count = 10
    let swalCountdownInterval
    const Deleteproject = async (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: "btn btn-danger btn-lg counter",
                cancelButton: "btn btn-primary btn-lg me-3",
            },
            buttonsStyling: false,
        });

        swalWithBootstrapButtons
            .fire({
                title: "Are you sure you want to delete?",
                text: "You won't be able to revert this!",
                imageUrl: '../../icon/alert.svg',
                imageWidth: 80,
                imageHeight: 80,
                confirmButtonText: "OK (10)",
                cancelButtonText: "Cancel",
                showCancelButton: true,
                reverseButtons: true,
                didOpen: () => {
                    $(".swal2-confirm").attr('disabled', true);
                    swalCountdownInterval = setInterval(function () {
                        count--
                        if (count < 1) {
                            $(".counter").text(`OK`)
                            $(".swal2-confirm").attr('disabled', false);
                            clearInterval(swalCountdownInterval)
                        } else {
                            $(".counter").text(`OK (${count})`)
                        }
                    }, 1000);
                }
            })
            .then(async (result) => {
                if (result.isConfirmed) {
                    const ind = reloadId.indexOf(params.id)
                    reloadId.splice(ind, 1)
                    console.log('first dsajkfhnakj',)
                    await API.post(`/delete_dl_rc_info/${id?.id || params?.id}`, {}, { headers: { authorization: `${token}` } });
                    if (reloadId.length === 0) {
                        setid({ id: "" })
                        navigate('/dl-rc-info')
                        // window.location.replace(`${process.env.REACT_APP_BASE_URL}`)
                        // window.location.replace(`http://localhost:3000/DL_RC_info`)
                    } else {
                        navigate(`/dl-rc-info/view/${reloadId[0]}`)
                        // window.location.replace(`http://localhost:3000/view/DL_RC_info/${reloadId[0]}`)
                        // window.location.replace(`${process.env.REACT_APP_BASE_URL}view/DL_RC_info/${reloadId[0]}`)
                    }
                } else {
                    count = 10
                    clearInterval(swalCountdownInterval)
                }
            });
    };



    return (
        <Layout sidebar={true}>
            {/* {isLoading && <div className="loader" ></div>} */}
            <div className="page-heading">
                <h3><Link to="/dl-rc-info" className='btn btn-primary btn-icon-lg me-3'><i className='bx bxs-left-arrow-alt'></i></Link>DL / RC Info View</h3>
                <div className="page-heading-right">
                    <SelectPicker data={DropDropValue} defaultValue={id.id} value={id.id} cleanable={false} className="wv-200 my-1 ms-3" onChange={(e) => selectpickerData(e)} placeholder="Select Title" placement="bottomEnd" />
                    {userPermission["DL-Rc-info"]?.edit ==1&&<Link to={`/dl-rc-info/edit/${id.id}`}>
                        <Button variant="primary ms-3 my-1" value="edit">Edit</Button>
                    </Link>}
                    {userPermission["DL-Rc-info"]?.delete==1 && <Button variant="danger ms-3 my-1 btn-icon-lg" type="button" onClick={(i) => Deleteproject(id.id)}><i className="bx bx-trash-alt"></i></Button>
}
                </div>
            </div>
            <div className='page-content'>
                <Card>
                    <Card.Body>
                        <Row>
                            <Col md={4}>
                                <div className='mb-4'>
                                    <p className='mb-0 fw-bold'>Type</p><span>{Data.type == 1 && "DL" || Data.type == 2 && "RC"}</span>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className='mb-4'>
                                    <p className='mb-0 fw-bold'>Title</p><span>{Data.title}</span>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className='mb-4'>
                                    <p className='mb-1 fw-bold'>English</p>
                                    <a href={Data.en} target="_blank" >
                                        <Button variant="outline-info" size="sm" className="btn-icon">
                                            <i className="bx bx-link-external"></i>
                                        </Button>
                                    </a>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className='mb-4'>
                                    <p className='mb-1 fw-bold'>Hindi</p>
                                    <a href={Data.hi} target="_blank" >
                                        <Button variant="outline-info" size="sm" className="btn-icon">
                                            <i className="bx bx-link-external"></i>
                                        </Button>
                                    </a>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className='mb-4'>
                                    <p className='mb-1 fw-bold'>Marathi</p>
                                    <a href={Data.mr} target="_blank" >
                                        <Button variant="outline-info" size="sm" className="btn-icon">
                                            <i className="bx bx-link-external"></i>
                                        </Button>
                                    </a>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className='mb-4'>
                                    <p className='mb-1 fw-bold'>Gujarati</p>
                                    <a href={Data.gu} target="_blank" >
                                        <Button variant="outline-info" size="sm" className="btn-icon">
                                            <i className="bx bx-link-external"></i>
                                        </Button>
                                    </a>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className='mb-4'>
                                    <p className='mb-1 fw-bold'>Tamil</p>
                                    <a href={Data.ta} target="_blank" >
                                        <Button variant="outline-info" size="sm" className="btn-icon">
                                            <i className="bx bx-link-external"></i>
                                        </Button>
                                    </a>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className='mb-4'>
                                    <p className='mb-1 fw-bold'>Kannada</p>
                                    <a href={Data.kn} target="_blank" >
                                        <Button variant="outline-info" size="sm" className="btn-icon">
                                            <i className="bx bx-link-external"></i>
                                        </Button>
                                    </a>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className='mb-4'>
                                    <p className='mb-1 fw-bold'>Telugu</p>
                                    <a href={Data.te} target="_blank" >
                                        <Button variant="outline-info" size="sm" className="btn-icon">
                                            <i className="bx bx-link-external"></i>
                                        </Button>
                                    </a>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className='mb-4'>
                                    <p className='mb-1 fw-bold'>Bangali</p>
                                    <a href={Data.bn} target="_blank" >
                                        <Button variant="outline-info" size="sm" className="btn-icon">
                                            <i className="bx bx-link-external"></i>
                                        </Button>
                                    </a>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className='mb-4'>
                                    <p className='mb-1 fw-bold'>Panjabi</p>
                                    <a href={Data.pn} target="_blank" >
                                        <Button variant="outline-info" size="sm" className="btn-icon">
                                            <i className="bx bx-link-external"></i>
                                        </Button>
                                    </a>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className='mb-4'>
                                    <p className='mb-1 fw-bold'>Odisha</p>
                                    <a href={Data.or} target="_blank" >
                                        <Button variant="outline-info" size="sm" className="btn-icon">
                                            <i className="bx bx-link-external"></i>
                                        </Button>
                                    </a>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className='mb-4'>
                                    <p className='mb-1 fw-bold'>Malyalam</p>
                                    <a href={Data.ml} target="_blank" >
                                        <Button variant="outline-info" size="sm" className="btn-icon">
                                            <i className="bx bx-link-external"></i>
                                        </Button>
                                    </a>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className='mb-4'>
                                    <p className='mb-1 fw-bold'>Thumb Image</p>
                                    <Fancybox>
                                        <a href={Data.thumb_image} data-fancybox="gallery">
                                            <img src={Data.thumb_image} className="hv-40 rounded-3" alt="" />
                                        </a>
                                    </Fancybox>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className='mb-4'>
                                    <p className='mb-0 fw-bold'>Status</p>
                                    <span>{Data.status == 1 ? "On" : "Off"}</span>
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </div>
        </Layout>
    )
}

export default RCDLinfoView